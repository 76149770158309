<template>
  <section class="hero-banner" :class="color">
    <h1 class="hero-heading">{{ heading }}</h1>
    <section v-if="breadcrumb" class="hero-breadcrumb">
      <a
        v-for="(link, index) in breadcrumb"
        :key="index"
        :href="link.url"
        class="breadcrumb-link"
      >
        {{ link.label }}
      </a>
    </section>
  </section>
</template>

<script>
export default {
  props: {
    color: String,
    heading: String,
    breadcrumb: Array
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.hero-banner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 24px;
  min-height: 163px;

  &.red {
    background-color: $lightRed;

    .hero-heading,
    .hero-breadcrumb .breadcrumb-link {
      color: $red;
    }
  }

  &.purple {
    background-color: $lightPurple;

    .hero-heading,
    .hero-breadcrumb .breadcrumb-link {
      color: $purple;
    }
  }

  &.yellow {
    background-color: $lightYellow;

    .hero-heading,
    .hero-breadcrumb .breadcrumb-link {
      color: $dark;
    }
  }

  &.gray {
    background-color: $lightGray;

    .hero-heading,
    .hero-breadcrumb .breadcrumb-link {
      color: $dark;
    }
  }

  &.blue {
    background-color: $lightBlue;

    .hero-heading,
    .hero-breadcrumb .breadcrumb-link {
      color: $blue2;
    }
  }

  .hero-heading {
    font-family: $fontBebas;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 155%;
    text-align: center;
    letter-spacing: 0.2em;
    margin: 0;
  }

  .hero-breadcrumb {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 8px;

    .breadcrumb-link {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;
      text-align: center;
      margin: 0 8px 8px 0;

      &:not(:last-child):after {
        content: '>';
        margin-left: 8px;
      }
    }
  }
}
</style>
