<template>
  <h1>test</h1>
</template>

<script>
import firebase from 'firebase/app'

export default {
  mounted() {
    const db = firebase.firestore()

    db.collection('users')
      .orderBy('jasInfo')
      .get()
      .then(querySnapshot => {
        const rates = []

        querySnapshot.forEach(doc => {
          const data = {
            id: doc.id,
            ...doc.data()
          }

          const { challenges } = data.jasInfo
          if (challenges.length) {
            challenges.forEach(challenge => {
              if (challenge.rate) {
                const foundChallenge = rates.find(
                  rate => rate.id === challenge.id
                )

                if (foundChallenge) {
                  foundChallenge.rates.push({
                    userId: data.id,
                    rate: challenge.rate
                  })
                } else {
                  rates.push({
                    id: challenge.id,
                    rates: [
                      {
                        userId: data.id,
                        rate: challenge.rate
                      }
                    ]
                  })
                }
              }
            })
          }
        })

        rates.forEach(rate => {
          db.collection('jas-challenges')
            .doc(rate.id)
            .set(
              {
                rates: rate.rates
              },
              { merge: true }
            )
        })
      })
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';
</style>
