<template>
  <div class="challenge-page">
    <HeroBanner
      :color="hero.color"
      :heading="hero.heading"
      :breadcrumb="hero.breadcrumb"
    />
    <section class="page-main">
      <section class="page-container">
        <span v-if="number" class="challenge-number" :class="difficulty">{{
          number
        }}</span>
        <h1 v-if="title" class="challenge-name" :class="hero.color">
          {{ title }}
        </h1>

        <div class="card-rating" :class="`color-${hero.color}`">
          <div
            class="rating-stars"
            :stars="rate.average"
            :class="`color-${hero.color}`"
          >
            <svg
              v-for="n in 5"
              :key="n"
              version="1.1"
              width="16"
              class="star rating"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 24 24"
              xml:space="preserve"
            >
              <path
                class="outline"
                d="M12,4.2L14.5,9l0.2,0.5l0.5,0.1l5.5,0.8L16.8,14l-0.4,0.4l0.1,0.5l1,5.3l-5-2.5L12,17.5l-0.4,0.2l-5,2.5L7.5,15l0.1-0.5 L7.2,14l-4-3.7l5.5-0.8l0.5-0.1L9.5,9L12,4.2 M11.9,2L8.6,8.6L1,9.7l5.5,5.1L5.2,22l6.8-3.4l6.8,3.4l-1.3-7.2L23,9.6l-7.6-1L11.9,2 L11.9,2z"
              />
              <polygon
                class="full"
                points="18.8,22 12,18.6 5.2,22 6.5,14.8 1,9.7 8.6,8.6 11.9,2 15.4,8.6 23,9.6 17.5,14.7"
              />
            </svg>
          </div>
          <p class="rating-votes">
            {{ `${rate.nbVotes} vote${rate.nbVotes > 1 ? 's' : ''}` }}
          </p>
        </div>

        <section v-if="subtitle" class="challenge-subtitle" v-html="subtitle" />

        <div v-if="video" class="challenge-video">
          <iframe
            class="video-iframe"
            :src="video"
            frameborder="0"
            allow="fullscreen; picture-in-picture"
            allowfullscreen=""
          ></iframe>
        </div>
        <div class="challenge-split">
          <section class="split-content">
            <section v-if="equipment.length" class="challenge-equipment">
              <p class="equipment-heading">Matériel</p>
              <div
                v-for="(item, index) in equipment"
                :key="index"
                class="equipment-item"
              >
                {{ item.name }}
                <img
                  v-if="item.icon"
                  :src="item.icon"
                  :alt="item.name"
                  class="item-icon"
                />
              </div>
            </section>
            <section v-if="content" class="challenge-content">
              <h3 class="content-heading">Déroulement</h3>
              <section class="content-content" v-html="content" />
            </section>
            <section v-if="challengeTips" class="challenge-content">
              <svg
                class="tips-icon"
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0)" fill="#89969F">
                  <path
                    d="M9.916 11.838s.763-.855 1.022-1.009c.26-.154.156.362.052 1.242-.104.88-1.087 5.227-.776 6.262.31 1.034 1.187 1.087 1.966.672.778-.414 2.187-1.967 2.433-2.38.193-.324.127-.972-.725-.26-1.073.894-1.188.984-1.034-.051.153-1.036.833-5.436.892-6.534.038-.725-.431-1.386-1.152-1.334-.72.052-1.374.518-2.29 1.593-1.268 1.489-1.268 2.614-.388 1.799zM13.03 7.287a1.268 1.268 0 100-2.535 1.268 1.268 0 000 2.535z"
                  />
                  <path
                    d="M12 1.4A10.6 10.6 0 111.4 12 10.611 10.611 0 0112 1.4zm0-1.403a12 12 0 10-.002 24 12 12 0 00.002-24z"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                  </clipPath>
                </defs>
              </svg>
              <section class="content-content" v-html="challengeTips" />
            </section>
            <section
              v-if="share"
              class="challenge-share"
              :class="hero.color"
              v-html="share"
            />
          </section>
          <section class="split-actions">
            <StopWatch v-if="stopWatch" :color="hero.color" />
            <button
              class="challenge-button"
              :class="hero.color"
              @click.prevent="doneIt"
            >
              <svg
                v-if="done"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 24"
              >
                <path
                  d="M15.058 6.123a.6.6 0 00.6.6h1.345a.6.6 0 100-1.2h-1.345a.6.6 0 00-.6.6zM5.838 6.123a.6.6 0 00.6.6h1.345a.6.6 0 000-1.2H6.438a.6.6 0 00-.6.6zM12.321 2.185V.84a.6.6 0 00-1.2 0v1.345a.6.6 0 101.2 0zM15.04 1.964l-.96.96a.601.601 0 00.85.85l.96-.96a.6.6 0 00-.85-.85zM8.937 3.939a.6.6 0 00.425-1.025l-.96-.96a.6.6 0 00-.85.85l.96.96a.596.596 0 00.425.175z"
                  fill="#fff"
                />
                <path
                  d="M1.725 10.858a1.2 1.2 0 00-1.2 1.2V22.56a1.2 1.2 0 001.2 1.2H5.2a1.2 1.2 0 001.2-1.116l.744.446c.708.425 1.52.65 2.345.65h5.071a4.444 4.444 0 001.786-.354c1.62-.72 1.874-1.985 1.754-2.825a2.64 2.64 0 00.78-2.934c.401-.485.615-1.1.6-1.73a2.515 2.515 0 00-.492-1.365 2.2 2.2 0 00-.55-2.515 2.343 2.343 0 00-.155-.133 2.293 2.293 0 00-.922-.408h-.026c-1.59-.338-2.662-.36-4.235-.36-.06-.362-.058-1.123.417-2.555.439-1.328.401-2.425-.115-3.262a2.71 2.71 0 00-1.937-1.233h-.075a1.269 1.269 0 00-1.236.897.685.685 0 00-.02.086l-.18 1.296c-.053.39-.167.768-.335 1.124l-1.75 3.674a2.063 2.063 0 01-1.469 1.2v-.286a1.201 1.201 0 00-1.2-1.2H1.725zm0 11.703V12.058H5.2V22.56H1.725zm4.8-9.01a3.249 3.249 0 002.424-1.92l1.736-3.664c.22-.466.368-.963.438-1.474l.185-1.212a.067.067 0 01.031-.017c.424.067.799.31 1.035.667.317.514.317 1.294 0 2.254-.466 1.401-.61 2.508-.432 3.29.112.497.556.848 1.066.844 1.586 0 2.566.014 4.08.334h.024c.16.03.311.093.444.188l.076.064c.615.554.226 1.265.145 1.397h-.001a.6.6 0 00.106.77c.233.237.377.547.406.88a1.647 1.647 0 01-.54 1.167.6.6 0 00-.08.72c.026.046.643 1.119-.578 2.016a.61.61 0 00-.211.682c.064.193.319 1.176-.999 1.755-.418.174-.867.26-1.32.25H9.48a3.36 3.36 0 01-1.725-.48L6.4 21.25v-7.68l.125-.019z"
                  fill="#fff"
                />
                <path
                  d="M4.194 20.693c0 .976-1.464.976-1.464 0s1.464-.976 1.464 0z"
                  fill="#fff"
                />
              </svg>
              {{ buttonLabel }}
            </button>
          </section>
        </div>
      </section>
    </section>
    <div class="popup-layout" :class="{ 'is-active': !!activePopup }"></div>
    <section
      class="popup-wrapper"
      :class="[{ 'is-active': activePopup === 'won' }, hero.color]"
    >
      <section class="popup-container">
        <div class="popup-close" @click="activePopup = false">
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              d="M18.3 5.71a.996.996 0 00-1.41 0L12 10.59 7.11 5.7A.996.996 0 105.7 7.11L10.59 12 5.7 16.89a.996.996 0 101.41 1.41L12 13.41l4.89 4.89a.996.996 0 101.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
              fill="#A0AAB4"
            />
          </svg>
        </div>
        <Particles v-if="justWon" id="confettis" :options="options" />
        <p v-if="justWon" class="won-heading">Bravo !</p>
        <p v-if="justWon" class="won-subtitle">1 point de gagné</p>

        <div
          v-if="justWon"
          class="card-rating"
          :class="{
            'is-editable': !isRated && justWon,
            'color-purple': hero.color === 'purple',
            'color-red': hero.color === 'red'
          }"
        >
          <p v-if="!isRated" class="rating-title">Note ce défi !</p>
          <p v-else class="rating-title">Merci !</p>

          <div
            class="rating-stars"
            :stars="myRating"
            :class="`color-${hero.color}`"
          >
            <svg
              v-for="index in 5"
              :key="index"
              version="1.1"
              width="16"
              class="star rating"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 24 24"
              xml:space="preserve"
              @click="rateChallenge(index)"
            >
              <path
                class="outline"
                d="M12,4.2L14.5,9l0.2,0.5l0.5,0.1l5.5,0.8L16.8,14l-0.4,0.4l0.1,0.5l1,5.3l-5-2.5L12,17.5l-0.4,0.2l-5,2.5L7.5,15l0.1-0.5 L7.2,14l-4-3.7l5.5-0.8l0.5-0.1L9.5,9L12,4.2 M11.9,2L8.6,8.6L1,9.7l5.5,5.1L5.2,22l6.8-3.4l6.8,3.4l-1.3-7.2L23,9.6l-7.6-1L11.9,2 L11.9,2z"
              />
              <polygon
                class="full"
                points="18.8,22 12,18.6 5.2,22 6.5,14.8 1,9.7 8.6,8.6 11.9,2 15.4,8.6 23,9.6 17.5,14.7"
              />
            </svg>
          </div>
        </div>

        <router-link to="/classement" class="popup-button dark"
          >Voir mon classement</router-link
        >
        <router-link
          v-if="hero.breadcrumb.length"
          :to="hero.breadcrumb[hero.breadcrumb.length - 1].url"
          class="popup-button"
          >Voir tous les défis
        </router-link>
        <a class="popup-button" @click="activePopup = 'share'">
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 25 16"
          >
            <path
              d="M14.935.805a.83.83 0 01.385.15l8.39 6c.191.137.302.345.302.566 0 .222-.11.43-.301.567l-8.39 6a.846.846 0 01-.826.08.721.721 0 01-.445-.643v-2.392c-1.774.015-7.873.324-12.331 3.892a.845.845 0 01-.947.054c-.293-.178-.422-.512-.315-.819C2.66 8.048 9.28 5.3 14.05 4.105v-2.58a.7.7 0 01.264-.544.84.84 0 01.62-.176zm.689 2.19v1.65c0 .328-.242.616-.59.698-3.854.893-9.08 2.893-11.832 6.96 5.267-2.834 11.176-2.64 11.676-2.618.419.02.747.337.746.72v1.643l6.325-4.523-6.325-4.53z"
              fill="#702784"
            />
          </svg>
          Partager
        </a>
      </section>
    </section>
    <section
      class="popup-wrapper"
      :class="[{ 'is-active': activePopup === 'share' }, hero.color]"
    >
      <section class="popup-container">
        <div class="popup-close" @click="activePopup = false">
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              d="M18.3 5.71a.996.996 0 00-1.41 0L12 10.59 7.11 5.7A.996.996 0 105.7 7.11L10.59 12 5.7 16.89a.996.996 0 101.41 1.41L12 13.41l4.89 4.89a.996.996 0 101.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
              fill="#A0AAB4"
            />
          </svg>
        </div>
        <p class="share-intro">Via les réseaux sociaux</p>
        <div class="share-social-links">
          <!-- Facebook -->
          <div class="social-link" @click="openPopup(facebookUrl)">
            <svg
              clip-rule="evenodd"
              fill-rule="evenodd"
              image-rendering="optimizeQuality"
              shape-rendering="geometricPrecision"
              text-rendering="geometricPrecision"
              viewBox="0 0 7600 7600"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6175 0H1425C638 0 0 638 0 1425v4750c0 787 638 1425 1425 1425h4750c787 0 1425-638 1425-1425V1425C7600 638 6962 0 6175 0zm-193 4093h-711v2632H4083V4093h-461v-887h461v-565c0-740 308-1180 1180-1180h884v883h-514c-340 0-362 127-362 363l-1 498h808l-97 887z"
                fill="#4267b2"
              />
            </svg>
          </div>
          <!-- Twitter -->
          <div class="social-link" @click="openPopup(twitterUrl)">
            <svg
              clip-rule="evenodd"
              fill-rule="evenodd"
              stroke-linejoin="round"
              stroke-miterlimit="2"
              viewBox="-89.009 -46.884 643.937 446.884"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M154.729 400c185.669 0 287.205-153.876 287.205-287.312 0-4.37-.089-8.72-.286-13.052A205.304 205.304 0 00492 47.346c-18.087 8.044-37.55 13.458-57.968 15.899 20.841-12.501 36.84-32.278 44.389-55.852a202.42 202.42 0 01-64.098 24.511C395.903 12.276 369.679 0 340.641 0c-55.744 0-100.948 45.222-100.948 100.965 0 7.925.887 15.631 2.619 23.025-83.895-4.223-158.287-44.405-208.074-105.504A100.739 100.739 0 0020.57 69.24c0 35.034 17.82 65.961 44.92 84.055a100.172 100.172 0 01-45.716-12.63c-.015.424-.015.837-.015 1.29 0 48.903 34.794 89.734 80.982 98.986a101.036 101.036 0 01-26.617 3.553c-6.493 0-12.821-.639-18.971-1.82 12.851 40.122 50.115 69.319 94.296 70.135-34.549 27.089-78.07 43.224-125.371 43.224A204.9 204.9 0 010 354.634c44.674 28.645 97.72 45.359 154.734 45.359"
                fill="#1da1f2"
                fill-rule="nonzero"
              />
            </svg>
          </div>
          <!-- Native -->
          <div class="social-link only-mobile" @click="nativeShare">
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M16.429 0a3.582 3.582 0 00-3.572 3.571c0 .322.036.643.143.929L6.393 7.821C5.714 6.964 4.714 6.43 3.57 6.43A3.582 3.582 0 000 10a3.582 3.582 0 003.571 3.571 3.521 3.521 0 002.786-1.357l6.607 3.322a3.4 3.4 0 00-.107.893A3.582 3.582 0 0016.43 20 3.582 3.582 0 0020 16.429a3.582 3.582 0 00-3.571-3.572c-1.143 0-2.143.536-2.822 1.357L7 10.893c.071-.286.143-.607.143-.929 0-.321-.036-.643-.143-.928l6.607-3.322c.679.893 1.679 1.429 2.822 1.429A3.582 3.582 0 0020 3.57 3.582 3.582 0 0016.429 0z"
                fill="#F04B54"
              />
            </svg>
          </div>
        </div>
        <p class="share-or"><span>ou</span></p>
        <p class="share-intro">Copier le lien ci-dessous</p>
        <div class="share-link-to-copy">
          <input type="text" readonly class="link-to-copy" :value="shareUrl" />
          <button class="button-for-copy" @click="copyUrl">
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 10"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5 8h3c.55 0 1 .45 1 1s-.45 1-1 1H5c-2.76 0-5-2.24-5-5s2.24-5 5-5h3c.55 0 1 .45 1 1s-.45 1-1 1H5C3.35 2 2 3.35 2 5s1.35 3 3 3zm10-8h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c1.65 0 3 1.35 3 3s-1.35 3-3 3h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c2.76 0 5-2.24 5-5s-2.24-5-5-5zM6 5c0 .55.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1H7c-.55 0-1 .45-1 1z"
                fill="#A0AAB4"
              />
            </svg>
          </button>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import axios from 'axios'
import { cloneDeep } from 'lodash'
import { options } from '@/confettis.json'
// eslint-disable-next-line import/extensions
import ChallengesDB from '@/firebase/challenges-db'
// eslint-disable-next-line import/extensions
import { getTypeName, getChallengeRate } from '../misc/helpers'
import HeroBanner from '@/components/HeroBanner.vue'
import StopWatch from '@/components/StopWatch.vue'

export default {
  components: { HeroBanner, StopWatch },
  data() {
    return {
      hero: {
        color:
          this.$route.params.sportName === 'activite-physique'
            ? 'red'
            : 'purple',
        heading: '',
        breadcrumb: []
      },
      id: '',
      title: '',
      subtitle: '',
      video: '',
      difficulty: '',
      number: '',
      equipment: [],
      content: '',
      challengeTips: '',
      stopWatch: false,
      share: '',
      activePopup: false,
      justWon: false,
      options,
      rate: {
        avergage: 0,
        nbVotes: 0
      },
      isRated: false,
      myRating: 0
    }
  },
  computed: {
    buttonLabel() {
      let label = ''

      if (this.$route.params.typeName === 'entrainements') {
        if (this.done) {
          label = 'Voir plus'
        } else {
          label = 'Complété'
        }
      } else if (this.done) {
        label = 'Voir plus'
      } else {
        label = 'Réussi !'
      }

      return label
    },
    shareUrl() {
      let url = window.location.href.split('/')
      url.pop()
      url = url.join('/')
      url = `${url}?redirectChallenge=${this.$route.params.challengeName}`
      return url
    },
    typeName() {
      return getTypeName(this.$route.params.typeName)
    },
    done() {
      let done = false

      if (
        this.user &&
        this.user.jasInfo &&
        this.user.jasInfo.challenges.length
      ) {
        done = !!this.user.jasInfo.challenges.find(el => el.id === this.id)
      }

      return done
    },
    facebookUrl() {
      return `https://www.facebook.com/dialog/share?app_id=87741124305&href=${this.shareUrl}`
    },
    twitterUrl() {
      return `https://twitter.com/intent/tweet?url=${this.shareUrl}`
    },
    ...mapState('app', ['appTitle', 'apiUrl', 'bearer']),
    ...mapState('authentication', ['user'])
  },
  async mounted() {
    const {
      sportName,
      typeName,
      categoryName,
      challengeName
    } = this.$route.params

    const sectionSlug =
      sportName === 'activite-physique' ? 'physicalActivity' : 'sports'
    const graphQlSection =
      sportName === 'activite-physique'
        ? 'physicalActivity_activitePhysique_Entry'
        : 'sports_sports_Entry'

    const query = `query MyQuery {
        entry(section: "${sectionSlug}", slug: "${challengeName}") {
          ... on ${graphQlSection} {
            id
            title
            subTitle
            challengeLevel
            challengeOrder
            challengeVideo {
              embeddedAsset {
                iframeSrc(params: "")
              }
            }
            materiels {
              ... on materiels_BlockType {
                nom
                icone {
                  url
                }
              }
            }
            contenu
            challengeTips
            textePartage
            activeChrono
            sport {
              title
              slug
            }
          }
        }
      }`

    try {
      const result = await axios({
        method: 'POST',
        url: this.apiUrl,
        data: {
          query
        },
        headers: {
          Authorization: `Bearer ${this.bearer}`
        }
      })

      const { entry } = result.data.data

      // Heading
      this.hero.heading = entry.sport[0].title

      // Breadcrumb
      const breadcrumb = []

      // Level 1
      breadcrumb.push({
        label: `${this.hero.heading}`,
        url: `/sport/${sportName}`
      })
      // Level 2
      breadcrumb.push({
        label: `${this.typeName}`,
        url: `/sport/${sportName}/${typeName}`
      })
      // Level 3
      if (entry.sport.length > 1) {
        breadcrumb.push({
          label: `${entry.sport[1].title}`,
          url: `/sport/${sportName}/${typeName}/${categoryName}`
        })
      }

      this.hero.breadcrumb = breadcrumb

      // Challenge Info
      this.id = entry.id
      this.title = entry.title
      this.subtitle = entry.subTitle
      this.difficulty = entry.challengeLevel
      this.number = entry.challengeOrder
      if (entry.challengeVideo.length) {
        this.video = entry.challengeVideo[0].embeddedAsset.iframeSrc
      }
      this.equipment = entry.materiels.map(el => ({
        name: el.nom,
        icon: el.icone.length ? el.icone[0].url : null
      }))
      this.content = entry.contenu
      this.challengeTips = entry.challengeTips
      this.stopWatch = entry.activeChrono
      this.share = entry.textePartage
      this.rate = await getChallengeRate(entry.id)
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    async doneIt() {
      if (!this.user) {
        const path = `/connexion?redirectUrl=${this.$route.path}`
        this.$router.push(path)
      } else {
        if (!this.done) {
          const challengeObject = {
            id: this.id,
            date: new Date()
          }

          const user = cloneDeep(this.user)

          user.jasInfo.challenges.push(challengeObject)

          await this.updateUser(user)

          this.justWon = true
        }

        this.activePopup = 'won'
      }
    },
    copyUrl() {
      /* Get the text field */
      const copyText = document.querySelector('.link-to-copy')

      /* Select the text field */
      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand('copy')

      const tempUrl = this.url
      this.url = 'Copié !'

      setTimeout(() => {
        this.url = tempUrl
      }, 3000)
    },
    openPopup(url) {
      // eslint-disable-next-line no-restricted-globals
      const dualScreenLeft =
        // eslint-disable-next-line no-restricted-globals
        window.screenLeft !== undefined ? window.screenLeft : screen.left
      const dualScreenTop =
        // eslint-disable-next-line no-restricted-globals
        window.screenTop !== undefined ? window.screenTop : screen.top

      // eslint-disable-next-line no-restricted-globals,no-nested-ternary
      const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : // eslint-disable-next-line no-restricted-globals
          screen.width
      // eslint-disable-next-line no-restricted-globals,no-nested-ternary
      const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : // eslint-disable-next-line no-restricted-globals
          screen.height

      const left = width / 2 - 600 / 2 + dualScreenLeft
      const top = height / 2 - 600 / 2 + dualScreenTop
      const newWindow = window.open(
        url,
        'JAS',
        `scrollbars=yes, width=600, height=600, top=${top}, left=${left}`
      )

      // Puts focus on the newWindow
      if (window.focus) {
        newWindow.focus()
      }
    },
    async nativeShare() {
      await navigator.share({
        title: this.title,
        url: this.shareUrl
      })
    },
    handleRate(value) {
      this.vote = value
    },
    async rateChallenge(value) {
      const challengesDb = new ChallengesDB()
      const challengeItem = await challengesDb.read(this.id)
      const newRateItem = {
        userId: this.user.id,
        rate: value
      }

      if (challengeItem) {
        await challengesDb.update({
          id: this.id,
          rates: [...challengeItem.rates, newRateItem]
        })
      } else {
        await challengesDb.create({ rate: [newRateItem] }, this.id)
      }

      this.myRating = value
      this.isRated = true
    },
    ...mapActions('authentication', ['updateUser'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.challenge-page {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $light;

  .page-main {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 56px 16px 80px;

    .page-container {
      width: 100%;
      max-width: 800px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .challenge-number {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        text-align: center;
        color: $light;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $gray;
        margin-bottom: 10px;

        &.easy {
          background-color: $easy;
        }

        &.medium {
          background-color: $medium;
          color: $dark;
        }

        &.hard {
          background-color: $hard;
        }
      }

      .challenge-name {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 145%;
        text-align: center;
        padding: 15px 42px;
        text-transform: uppercase;
        border: 0.5px solid #702784;
        box-sizing: border-box;
        border-radius: 4px;
        margin: 0 0 16px;

        &.red {
          color: $red;
          border-color: $red;
        }

        &.purple {
          color: $purple;
          border-color: $purple;
        }
      }

      .challenge-subtitle {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 155%;
        text-align: center;
        color: #000000;
        margin: 0 0 32px;

        p {
          margin: 0 !important;
        }
      }

      .challenge-video {
        position: relative;
        width: 100%;
        min-height: 56.5vh;
        padding-top: 56.5%;
        margin-bottom: 32px;

        .video-iframe {
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100% !important;
        }
      }

      .challenge-split {
        width: 100%;
        display: flex;
        justify-content: space-between;

        @media (max-width: $mobileBreakpoint) {
          flex-direction: column;
          align-items: center;
        }

        .split-content {
          width: calc(100% - 282px);
          display: flex;
          flex-direction: column;
          align-items: center;

          @media (max-width: $mobileBreakpoint) {
            width: 100%;
            margin-bottom: 32px;
          }

          .challenge-equipment {
            padding: 18px;
            border-radius: 16px;
            min-width: 200px;
            background-color: $lightGray;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 32px;

            .equipment-heading {
              width: 100%;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 155%;
              text-align: center;
              margin: 0 0 8px;
            }

            .equipment-item {
              display: flex;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 155%;
              text-align: center;

              .item-icon {
                height: 24px;
                margin-right: 5px;
              }
            }
          }

          .challenge-content {
            width: 100%;
            display: flex;
            flex-direction: column;

            .content-heading {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 155%;
              text-transform: uppercase;
              color: #89969f;
              margin: 0;
            }

            svg {
              height: 24px;
              margin-top: 8px;
            }

            .content-content {
              width: 100%;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 155%;

              p {
                margin: 0 0 16px;
              }

              ul li {
                color: #89969f;
              }
            }
          }

          .challenge-share {
            text-align: center;
            margin: 32px 0 0;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 155%;

            p {
              margin: 0;
            }

            strong {
              font-size: x-large;
            }

            &.red {
              color: $red;
            }

            &.purple {
              color: $purple;
            }
          }
        }

        .split-actions {
          width: 250px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .challenge-button {
            width: 100%;
            appearance: none;
            outline: none;
            background: $gray;
            border-radius: 8px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $fontFurura;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 155%;
            color: $light;
            padding: 12px;
            cursor: pointer;
            transition: opacity ease-out 0.2s;

            &:hover {
              opacity: 0.8;
            }

            &.red {
              background-color: $red;
            }

            &.purple {
              background-color: $purple;
            }

            svg {
              height: 24px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .popup-layout {
    position: fixed;
    inset: 0;
    background-color: rgba($dark, 0.6);
    z-index: 60;
    opacity: 0;
    pointer-events: none;
    transition: opacity ease-out 0.2s;

    &.is-active {
      pointer-events: auto;
      opacity: 1;
    }
  }

  .popup-wrapper {
    position: fixed;
    z-index: 61;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% + 40px));
    width: calc(100% - 48px);
    max-height: calc(100vh - 48px);
    overflow-y: scroll;
    max-width: 330px;
    background: $light;
    border-radius: 8px;
    opacity: 0;
    pointer-events: none;
    transition: opacity ease-out 0.2s, transform ease-out 0.2s;

    &.is-active {
      pointer-events: auto;
      opacity: 1;
      transform: translate(-50%, -50%);
    }

    &.red {
      .won-heading,
      .won-subtitle {
        color: $red;
      }

      .popup-button {
        border-color: $red;
        color: $red;

        &.dark {
          background-color: $red;
        }
      }

      .popup-container {
        .rating-title {
          color: $red;
        }
      }
    }

    &.purple .popup-container {
      .won-heading,
      .won-subtitle {
        color: $purple;
      }

      .popup-button {
        border-color: $purple;
        color: $purple;

        &.dark {
          background-color: $purple;
        }
      }

      .rating-title {
        color: $purple;
      }
    }

    .popup-close {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;

      svg {
        height: 24px;
      }
    }

    #confettis {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: -20px;
      pointer-events: none !important;
      z-index: 61;

      .tsparticles-canvas-el {
        position: absolute !important;
        inset: 0 !important;
        pointer-events: none !important;
      }
    }

    .popup-container {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 90px 24px 36px;

      & > * {
        z-index: 62;
      }

      .card-rating {
        flex-direction: column;
      }

      .won-heading {
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 155%;
        letter-spacing: 0.2em;
        margin: 0 0 8px;
      }

      .won-subtitle {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 155%;
        margin: 0 0 32px;
      }

      .popup-button {
        width: 100%;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 145%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $dark;
        padding: 16px;
        background-color: $light;
        border: 1px solid $dark;
        box-sizing: border-box;
        border-radius: 8px;
        margin-bottom: 16px;
        transition: opacity ease-out 0.2s;
        cursor: pointer;

        &.dark {
          background-color: $dark;
          color: $light;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          opacity: 0.8;
        }

        svg {
          height: 14px;
          margin-right: 10px;
        }
      }

      .share-intro {
        width: 100%;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 155%;
        color: $gray;
        margin: 0 0 8px;
      }

      .share-social-links {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 32px;

        .social-link {
          width: 40px;
          height: 40px;
          border-radius: 40px;
          border: 0.833333px solid $lightGray;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          transition: background-color ease-out 0.2s;
          cursor: pointer;

          &:hover {
            background-color: $lightGray;
          }

          &.only-mobile {
            display: none;

            @media (max-width: 1023px) {
              display: flex;
            }
          }

          svg {
            height: 20px;
          }
        }
      }

      .share-or {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 25px;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: calc(50% - 0.5px);
          height: 1px;
          background-color: $lightGray;
        }

        span {
          position: relative;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 155%;
          text-align: center;
          color: $gray;
          padding: 0 16px;
          background-color: $light;
        }
      }

      .share-link-to-copy {
        width: 100%;
        display: flex;
        align-items: center;
        border: 1px solid $lightGray;
        border-radius: 8px;
        padding: 8px;

        .button-for-copy {
          flex-shrink: 0;
          width: 40px;
          height: 40px;
          border: 1px solid $lightGray;
          background-color: $light;
          box-sizing: border-box;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background-color ease-out 0.2s;
          cursor: pointer;

          &:hover {
            background-color: $lightGray;
          }

          svg {
            height: 10px;
          }
        }

        .link-to-copy {
          flex: 1;
          padding-right: 16px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 155%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
          appearance: none;
          border: none;
          outline: none;
        }
      }
    }
  }

  .card-rating {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 22px;

    &.color-purple {
      .rating-stars .star path,
      .rating-stars .star polygon {
        stroke: $purple;
      }

      .rating-stars[stars] {
        .star path,
        .star polygon {
          fill: $purple;
        }
      }

      &.is-editable .rating-stars[stars] {
        .star path,
        .star polygon {
          fill: $light;
        }
      }
    }

    &.color-red {
      .rating-stars .star path,
      .rating-stars .star polygon {
        stroke: $red;
      }

      .rating-stars[stars] {
        .star path,
        .star polygon {
          fill: $red;
        }
      }
    }

    .rating-stars {
      display: flex;
      margin-right: 16px;

      &.color-purple {
        .star path,
        .star polygon {
          stroke: $purple !important;
        }
      }

      &.color-red {
        .star path,
        .star polygon {
          stroke: $red !important;
        }
      }

      .star {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }

        polygon,
        path {
          fill: $light;
        }
      }

      &[stars='0'] {
        .star path,
        .star polygon {
          fill: $light;
        }
      }

      &[stars='1'] {
        .star:nth-child(1) ~ .star path,
        .star:nth-child(1) ~ .star polygon {
          fill: $light;
        }
      }

      &[stars='2'] {
        .star:nth-child(2) ~ .star path,
        .star:nth-child(2) ~ .star polygon {
          fill: $light;
        }
      }

      &[stars='3'] {
        .star:nth-child(3) ~ .star path,
        .star:nth-child(3) ~ .star polygon {
          fill: $light;
        }
      }

      &[stars='4'] {
        .star:nth-child(4) ~ .star path,
        .star:nth-child(4) ~ .star polygon {
          fill: $light;
        }
      }

      &[stars='5'] {
        .star:nth-child(5) ~ .star path,
        .star:nth-child(5) ~ .star polygon {
          fill: $light;
        }
      }
    }

    &.is-editable {
      .rating-stars {
        svg {
          cursor: pointer;
        }

        &.color-purple {
          &:hover {
            .star path,
            .star polygon {
              fill: $purple;
            }
          }
        }

        &.color-red {
          &:hover {
            .star path,
            .star polygon {
              fill: $red;
            }
          }
        }

        &:hover .star:hover:nth-child(1) ~ .star {
          polygon,
          path {
            fill: $light !important;
          }
        }

        &:hover .star:hover:nth-child(2) ~ .star {
          polygon,
          path {
            fill: $light !important;
          }
        }

        &:hover .star:hover:nth-child(3) ~ .star {
          polygon,
          path {
            fill: $light !important;
          }
        }

        &:hover .star:hover:nth-child(4) ~ .star {
          polygon,
          path {
            fill: $light !important;
          }
        }

        &:hover.star:hover:nth-child(5) ~ .star {
          polygon,
          path {
            fill: $light !important;
          }
        }
      }
    }
  }

  .rating-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    color: $gray;
    margin-right: 8px;
  }

  .rating-votes {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 155%;
    color: $gray;
    font-family: $fontFurura;
  }
}
</style>
