import { isNil } from 'lodash'
// eslint-disable-next-line import/extensions
import store from '@/store'

const {userAgent} = navigator
// eslint-disable-next-line no-unused-vars
let browserName;

if (userAgent.match(/chrome|chromium|crios/i)) {
  browserName = "chrome";
} else if (userAgent.match(/firefox|fxios/i)) {
  browserName = "firefox";
} else if (userAgent.match(/safari/i)) {
  browserName = "safari";
} else if (userAgent.match(/opr\//i)) {
  browserName = "opera";
} else if (userAgent.match(/edg/i)) {
  browserName = "edge";
}

const ua = navigator.userAgent.toLowerCase()
const isAndroid = ua.indexOf("android") > -1

const isIosOnBrowser =
  ['iPhone', 'iPad', 'iPod'].includes(navigator.platform) &&
  !window.navigator.standalone

const isAndroidOnBrowser =
  !['iPhone', 'iPad', 'iPod'].includes(navigator.platform) &&
  isAndroid &&
  !window.navigator.standalone

if ((isIosOnBrowser && browserName === 'safari') || (isAndroidOnBrowser && browserName === 'chrome')) {
  const now = Date.now()
  let limitDate = null
  const addToHomeIosPromptLastDate = localStorage.getItem(
    'addToHomeIosPromptLastDate'
  )

  if (!isNil(addToHomeIosPromptLastDate)) {
    limitDate = new Date(parseInt(addToHomeIosPromptLastDate, 10))
    limitDate.setMonth(limitDate.getMonth() + 1)
  }

  if (isNil(limitDate) || now >= limitDate.getTime()) {
    if (browserName === 'safari') {
      store.commit('app/setShowAddToHomeScreenModalForApple', true)
    } else if (browserName === 'chrome') {
      store.commit('app/setShowAddToHomeScreenModalForAndroid', true)
    }
  }
}
