// eslint-disable-next-line import/extensions
import UsersDB from '@/firebase/users-db'
// eslint-disable-next-line import/extensions
import ChallengesDB from '@/firebase/challenges-db'

/**
 * Create new user from firebase auth user infos
 */
// eslint-disable-next-line
export const createNewUserFromFirebaseAuthUser = async (firebaseAuthUser, additionalUserInfo) => {
  const referencedBy = new URLSearchParams(document.location.search).get("referencedBy")
  const providerData = firebaseAuthUser.providerData[0]
  console.log('firebase user', firebaseAuthUser)
  const { displayName, photoURL, email } = providerData
  const userDb = new UsersDB()
  const user = {
    displayName,
    photoURL,
    email,
    firstname: additionalUserInfo.firstname,
    lastname: additionalUserInfo.lastname,
    jasInfo: {
      type: '',
      typeOther: '',
      city: '',
      school: '',
      schoolLevel: '',
      teamMember: false,
      sport: '',
      newsletter: false,
      challenges: []
    }
  }

  if (referencedBy) {
    user.jasInfo.referencedBy = referencedBy
  }

  return userDb.create(user, firebaseAuthUser.uid)
}

export const capitalizeTheFirstLetterOfEachWord = words => {
  const separateWord = words.toLowerCase().split(' ')
  for (let i = 0; i < separateWord.length; i += 1) {
    separateWord[i] =
      separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1)
  }
  return separateWord.join(' ')
}

export const getSportName = sportName => {
  return capitalizeTheFirstLetterOfEachWord(sportName)
}

export const getTypeName = typeName => {
  return typeName === 'defis' ? 'Défis' : 'Entraînements'
}

export const backOnError = () => {
  setTimeout(() => {
    const element = document.querySelector('.form-error')
    element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, 400)
}

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const isProfileComplete = user => {
  let validation = true

  if (
    !(user.firstname && user.firstname.length) ||
    !(user.lastname && user.lastname.length) ||
    !user.jasInfo ||
    !(user.jasInfo.type && user.jasInfo.type.length) ||
    !(user.jasInfo.city && user.jasInfo.city.length)
  ) {
    validation = false
  }

  return validation
}

export const slugify = str => {
  str = str.replace(/^\s+|\s+$/g, '') // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
  const to = 'aaaaeeeeiiiioooouuuunc------'
  for (let i = 0, l = from.length; i < l; i += 1) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }
  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
  return str
}

export const getChallengeRate = async challengeId => {
  const challengesDb = new ChallengesDB()
  const challenge = await challengesDb.read(challengeId)

  let average = 0
  let sumRates = 0
  let nbVotes = 0

  challenge.rates.forEach(rate => {
    sumRates += rate.rate
    nbVotes += 1
  })

  if (nbVotes > 0) {
    average = Math.ceil(sumRates / nbVotes)
  }

  return {
    average,
    nbVotes
  }
}
