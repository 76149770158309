<template>
  <section class="glossary-page">
    <HeroBanner color="yellow" :heading="heading" />
    <section class="glossary-section">
      <div class="glossary-filters" data-aos="fade-up">
        <div class="glossary-filters-wrapper">
          <div
            v-for="(item, index) in glossary"
            :key="index"
            class="glossary-filter"
            @click.prevent="scroll(item.letter)"
          >
            {{ item.letter }}
          </div>
        </div>
      </div>

      <div class="glossary-list">
        <div
          v-for="(item, index) in glossary"
          :key="index"
          :data-letter="item.letter"
        >
          <h4 class="glossary-list-heading bold">{{ item.letter }}</h4>
          <router-link
            v-for="(i, ind) in item.items"
            :key="`lex-${ind}`"
            :to="`lexique/${i.slug}`"
            class="glossary-link"
          >
            <span>{{ i.title }}</span>
          </router-link>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
// eslint-disable-next-line import/extensions
import { slugify } from '@/misc/helpers'
import HeroBanner from '@/components/HeroBanner.vue'

export default {
  components: { HeroBanner },
  data() {
    return {
      heading: 'S\'informer',
      glossary: []
    }
  },
  computed: {
    ...mapState('app', ['apiUrl', 'bearer'])
  },
  async mounted() {
    const query = `query MyQuery {
      entries(section: "glossary") {
        ... on glossary_default_Entry {
          id
          slug
          title
        }
      }
    }
`

    try {
      const result = await axios({
        method: 'POST',
        url: this.apiUrl,
        data: {
          query
        },
        headers: {
          Authorization: `Bearer ${this.bearer}`
        }
      })

      let { entries } = result.data.data

      entries = entries.map(el => {
        return {
          slugifiedTitle: slugify(el.title),
          ...el
        }
      })

      entries.sort((a, b) => {
        return b.slugifiedTitle - a.slugifiedTitle
      })

      entries.forEach(({ slugifiedTitle, title, slug }) => {
        const firstLetter = slugifiedTitle.charAt(0)
        const foundGlossary = this.glossary.find(e => {
          return e.letter === firstLetter
        })

        if (foundGlossary) {
          foundGlossary.items.push({ title, slug })
        } else {
          this.glossary.push({ letter: firstLetter, items: [{ title, slug }] })
        }
      })
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    scroll(letter) {
      const $target = document.querySelector(`[data-letter="${letter}"]`)

      if ($target) {
        const $menu = document.querySelector('.header')
        const $letters = document.querySelector('.glossary-filters')

        const rect = $target.getBoundingClientRect()
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop

        const destination =
          rect.top + scrollTop - $menu.offsetHeight - $letters.offsetHeight - 16

        window.scrollTo({
          top: destination,
          behavior: 'smooth'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.glossary-page {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $light;

  .glossary-section {
    flex: 1;
    width: 100%;
    padding: 50px 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: $light;
    max-width: 500px;
  }

  .glossary-filters {
    width: 100%;
    position: sticky;
    top: 75px;
    z-index: 10;
    background-color: #fff;
    padding-bottom: 24px;

    @media (max-width: 767px) {
      top: 69px;
      padding-top: 10px;
    }

    .glossary-filters-wrapper {
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 767px) {
        flex-wrap: nowrap;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .glossary-filter {
      width: 40px;
      height: 40px;
      min-width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 16px 16px 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 155%;
      text-transform: capitalize;
      cursor: pointer;
      color: $gray;
      border: 1px solid $gray;
      border-radius: 8px;
      transition: background ease-out 0.2s, color ease-out 0.2s;

      @media (max-width: 767px) {
        &:last-child {
          margin-right: 16px;
        }
      }

      &:hover {
        background: $gray;
        color: $light;
      }
    }
  }

  .glossary-list {
    width: 100%;
    display: flex;
    flex-direction: column;

    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .glossary-list-heading {
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 155%;
      margin: 0;
      text-transform: uppercase;
    }
  }

  .glossary-link {
    width: 100%;
    display: flex;
    border-radius: 4px;
    padding: 18px 56px 18px 16px;
    background-color: #edeff7;
    margin-top: 8px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuMjA4NzQgMTNIMTYuMzc4N0wxMS40OTg3IDE3Ljg4QzExLjEwODcgMTguMjcgMTEuMTA4NyAxOC45MSAxMS40OTg3IDE5LjNDMTEuODg4NyAxOS42OSAxMi41MTg3IDE5LjY5IDEyLjkwODcgMTkuM0wxOS40OTg3IDEyLjcxQzE5Ljg4ODcgMTIuMzIgMTkuODg4NyAxMS42OSAxOS40OTg3IDExLjNMMTIuOTE4NyA0LjY5OTk5QzEyLjczMTkgNC41MTI3NCAxMi40NzgzIDQuNDA3NTEgMTIuMjEzNyA0LjQwNzUxQzExLjk0OTIgNC40MDc1MSAxMS42OTU2IDQuNTEyNzQgMTEuNTA4NyA0LjY5OTk5QzExLjExODcgNS4wODk5OSAxMS4xMTg3IDUuNzE5OTkgMTEuNTA4NyA2LjEwOTk5TDE2LjM3ODcgMTFINS4yMDg3NEM0LjY1ODc0IDExIDQuMjA4NzQgMTEuNDUgNC4yMDg3NCAxMkM0LjIwODc0IDEyLjU1IDQuNjU4NzQgMTMgNS4yMDg3NCAxM1oiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=');
    background-position: center right 20px;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    color: $dark;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 145%;
    }
  }
}
</style>
