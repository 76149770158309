<template>
  <section class="glossary-page">
    <HeroBanner
      color="yellow"
      :heading="heading"
      :breadcrumb="hero.breadcrumb"
    />
    <section class="glossary-section">
      <h2 class="glossary-page-title">{{ title }}</h2>
      <div v-if="video" class="glossary-video">
        <iframe
          class="video-iframe"
          :src="video"
          frameborder="0"
          allow="fullscreen; picture-in-picture"
          allowfullscreen=""
        ></iframe>
      </div>
      <div class="glossary-page-content" v-html="content" />
      <router-link to="/lexique" class="button fluid glossary-page-button"
        >Retour à la liste complète</router-link
      >
    </section>
  </section>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import HeroBanner from '@/components/HeroBanner.vue'

export default {
  components: { HeroBanner },
  data() {
    return {
      heading: 'S\'informer',
      title: '',
      video: '',
      content: ''
    }
  },
  computed: {
    hero() {
      return {
        heading: `Lexique`
      }
    },
    ...mapState('app', ['appTitle', 'apiUrl', 'bearer'])
  },
  watch: {
    $route (to, from){
      if (to.name === from.name && to.name === 'glossary-page' && to.path !== from.path) {
        this.getInfos()
        window.scrollTo(0,0)
      }
    }
  },
  mounted() {
    this.getInfos()
  },
  methods: {
    checkForInternalLinks() {
      setTimeout(() => {
        const url = 'https://jeuneactifausecondaire.ca'
        const $links = [].slice.call(document.querySelectorAll(`.glossary-page-content a[href^="${url}"]`))

        if ($links.length) {
          $links.forEach(($link) => {
            $link.addEventListener('click', (e) => {
              e.preventDefault()

              const route = $link.getAttribute('href').split(url)[1]
              this.$router.push(route)
            })
          })
        }
      }, 200)
    },
    async getInfos() {
      const { glossaryName } = this.$route.params

      const query = `query MyQuery {
        entries(section: "glossary", slug: "${glossaryName}") {
          id
          ... on glossary_default_Entry {
            id
            title
            contenu
            challengeVideo {
              ... on common_Asset {
                id
                embeddedAsset {
                  iframeSrc(params: "")
                }
              }
            }
          }
        }
      }`

      try {
        const result = await axios({
          method: 'POST',
          url: this.apiUrl,
          data: {
            query
          },
          headers: {
            Authorization: `Bearer ${this.bearer}`
          }
        })

        const { entries } = result.data.data

        this.title = entries[0].title
        this.content = entries[0].contenu

        if (entries[0].challengeVideo.length) {
          this.video = entries.challengeVideo[0].embeddedAsset.iframeSrc
        }

        this.checkForInternalLinks()
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.glossary-page {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $light;

  .glossary-section {
    flex: 1;
    width: 100%;
    padding: 50px 24px;
    display: flex;
    flex-direction: column;
    background-color: $light;
    max-width: 500px;
  }

  .glossary-page-title {
    width: 100%;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    margin: 0 0 16px;
    text-align: left;
  }

  .glossary-page-content {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    color: $gray;
    margin-bottom: 48px;
    text-align: justify;

    p {
      margin: 0 0 40px;
    }

    figure {
      width: 100%;
      margin: 0 0 40px;

      img {
        width: 100%;
      }
    }
  }

  .glossary-page-button {
    background-color: $light;
    color: $dark;
    border: 1px solid $dark;
  }

  .glossary-video {
    position: relative;
    width: 100%;
    padding-top: 56.5%;
    margin-bottom: 32px;

    .video-iframe {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
