<template>
  <section class="contact-page">
    <HeroBanner color="gray" :heading="heading" />
    <section class="form-container">
      <form class="form-wrapper">
        <!-- Error -->
        <div v-if="error" class="form-error">{{ error }}</div>
        <!-- Firstname -->
        <section class="input-container">
          <label for="register-firstname" class="form-label">Votre nom</label>
          <input id="register-firstname" v-model="name" type="text" class="form-input" :class="{'has-error': errorInputs.includes('name')}" placeholder="Votre nom" />
        </section>
        <!-- Email -->
        <section class="input-container">
          <label for="register-email" class="form-label">Adresse courriel</label>
          <input id="register-email" v-model="email" type="email" class="form-input" :class="{'has-error': errorInputs.includes('email')}" placeholder="Votre courriel" />
        </section>
        <!-- Message -->
        <section class="input-container">
          <label for="register-message" class="form-label">Message</label>
          <textarea id="register-message" v-model="message" class="form-textarea" :class="{'has-error': errorInputs.includes('message')}" placeholder="Votre message"/>
        </section>
        <!-- Button -->
        <button class="form-button" @click.prevent="validateForm">Envoyer</button>
      </form>
    </section>
    <div class="popup-layout" :class="{'is-active': activePopup}"></div>
    <section class="popup-wrapper" :class="{'is-active': activePopup}">
      <section class="popup-container">
        <p class="popup-heading">Merci !</p>
        <p class="popup-subtitle">
          Votre message a bien été envoyé. Nous allons l’examiner et reviendrons vers vous dans les plus brefs délais.
        </p>
        <router-link to="/" class="popup-button">Retour à la page d’accueil</router-link>
      </section>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex'
// eslint-disable-next-line import/extensions
import { backOnError, validateEmail } from '@/misc/helpers'
// eslint-disable-next-line import/extensions
import ContactDB from '@/firebase/contact-db'
import HeroBanner from '@/components/HeroBanner.vue'

export default {
  components: { HeroBanner },
  data() {
    return {
      heading: 'Nous joindre',
      name: '',
      email: '',
      message: '',
      error: null,
      errorInputs: [],
      activePopup: false
    }
  },
  computed: mapState('authentication', ['user']),
  mounted() {
    if (this.user) {
      this.email = this.user.email
      this.name = `${this.user.firstname} ${this.user.lastname}`
    }
  },
  methods: {
    validateForm() {
      this.error = ''
      this.errorInputs = []
      let validation = true

      // Check if fields are filled
      if (!this.name.length) {
        validation = false
        this.errorInputs.push('name')
      }
      if (!this.email.length) {
        validation = false
        this.errorInputs.push('email')
      }
      if (!this.message.length) {
        validation = false
        this.errorInputs.push('message')
      }
      if (!validation) {
        this.error = 'Veuillez remplir tous les champs.'
        backOnError()
        return false
      }

      // Check email format
      if (!validateEmail(this.email)) {
        this.errorInputs.push('email')
        this.error = 'Veuillez rentrer un format de courriel correct.'
        backOnError()
        return false
      }

      this.submit()
      return true
    },
    async submit() {
      const contactInfo = {
        name: this.name,
        email: this.email,
        message: this.message.replace(/\n\r?/g, '<br />')
      }
      await new ContactDB().create(contactInfo)

      this.activePopup = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.contact-page {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .popup-layout {
    position: fixed;
    inset: 0;
    background-color: rgba($dark, 0.6);
    z-index: 60;
    opacity: 0;
    pointer-events: none;
    transition: opacity ease-out 0.2s;

    &.is-active {
      pointer-events: auto;
      opacity: 1;
    }
  }

  .popup-wrapper {
    position: fixed;
    z-index: 61;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% + 40px));
    width: calc(100% - 48px);
    max-height: calc(100vh - 48px);
    overflow-y: scroll;
    max-width: 330px;
    background: $light;
    border-radius: 8px;
    opacity: 0;
    pointer-events: none;
    transition: opacity ease-out 0.2s, transform ease-out 0.2s;

    &.is-active {
      pointer-events: auto;
      opacity: 1;
      transform: translate(-50%, -50%);
    }

    .popup-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 56px 24px 36px;

      .popup-heading {
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 155%;
        letter-spacing: 0.2em;
        margin: 0 0 8px;
      }

      .popup-subtitle {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 155%;
        text-align: center;
        color: $gray;
        margin: 0 0 40px;
      }

      .popup-button {
        @extend .button;
        @extend .fluid;
      }
    }
  }
}
</style>
