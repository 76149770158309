export default {
  setNetworkOnline: (state, value) => (state.networkOnLine = value),
  setSWRegistrationForNewContent: (state, value) =>
    (state.SWRegistrationForNewContent = value),
  setShowAddToHomeScreenModalForApple: (state, value) =>
    (state.showAddToHomeScreenModalForApple = value),
  setShowAddToHomeScreenModalForAndroid: (state, value) =>
    (state.showAddToHomeScreenModalForAndroid = value),
  setRefreshingApp: (state, value) => (state.refreshingApp = value),
  setSportName: (state, value) => (state.sportName = value)
}
