import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueGtm from 'vue-gtm'
import Particles from 'particles.vue'

import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
// eslint-disable-next-line import/extensions
import '@/misc/register-service-worker'
// eslint-disable-next-line import/extensions
import '@/misc/handle-network-status'
// eslint-disable-next-line import/extensions
import '@/firebase/init'
// eslint-disable-next-line import/extensions
import '@/firebase/authentication'
// eslint-disable-next-line import/extensions
import '@/misc/handle-apple-install-prompt'
import 'pwacompat'

Vue.use(VueMeta)
Vue.use(Particles)
Vue.use(VueGtm, {
  id: 'GTM-KJC95G2'
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')
