<template>
  <div class="type-page">
    <HeroBanner
      :color="hero.color"
      :heading="hero.heading"
      :breadcrumb="hero.breadcrumb"
    />
    <section class="page-main">
      <p v-if="heading" class="main-text">{{ heading }}</p>
      <SportCards :color="hero.color" :cards="categories" />
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import HeroBanner from '@/components/HeroBanner.vue'
import SportCards from '@/components/SportCards.vue'
// eslint-disable-next-line import/extensions
import { getTypeName } from '@/misc/helpers'

export default {
  components: { HeroBanner, SportCards },
  data() {
    return {
      sportName: '',
      heading: 'Dans quoi veux-tu te lancer ?',
      categories: []
    }
  },
  computed: {
    typeName() {
      return getTypeName(this.$route.params.typeName)
    },
    hero() {
      const { sportName, typeName } = this.$route.params
      return {
        color: sportName === 'activite-physique' ? 'red' : 'purple',
        heading: `${this.sportName}`,
        breadcrumb: [
          {
            label: `${this.sportName}`,
            url: `/sport/${sportName}`
          },
          {
            label: `${this.typeName}`,
            url: `/sport/${sportName}/${typeName}`
          }
        ]
      }
    },
    ...mapState('app', ['appTitle', 'apiUrl', 'bearer'])
  },
  watch: {
    $route(to, from) {
      if (to.name === from.name) {
        if (
          to.params.sportName !== from.params.sportName ||
          to.params.typeName !== from.params.typeName
        ) {
          this.getInfos()
        }
      }
    }
  },
  mounted() {
    this.getInfos()
  },
  methods: {
    async getInfos() {
      const { sportName, typeName } = this.$route.params
      const parentId = typeName === 'defis' ? 218 : 219
      const query = `query MyQuery {
          category(group: "sports", level: 1, slug: "${sportName}") {
            title
            ... on sports_Category {
              catImage {
                url(format: "webp", width: 700, height: 500, mode: "crop", quality: 100)
              }
            }
            children(catType: "${parentId}") {
              slug
              title
              ... on sports_Category {
                id
                catImage {
                  url(format: "webp", width: 700, height: 500, mode: "crop", quality: 100)
                }
              }
            }
          }
        }`

      try {
        const result = await axios({
          method: 'POST',
          url: this.apiUrl,
          data: {
            query
          },
          headers: {
            Authorization: `Bearer ${this.bearer}`
          }
        })

        const { category } = result.data.data

        if (category) {
          this.sportName = category.title

          if (category.children.length) {
            this.categories = category.children.map(el => {
              return {
                id: el.id,
                slug: el.slug,
                name: el.title,
                image: el.catImage[0].url,
                url: `/sport/${sportName}/${typeName}/${el.slug}`
              }
            })
          } else {
            this.$router.push({
              path: `/sport/${sportName}/${typeName}/general`
            })
          }
          this.checkChallengeDone()
        }
      } catch (error) {
        console.error(error)
      }
    },
    async checkChallengeDone() {
      this.categories = await Promise.all(this.categories.map(async card => {
        const query = `query MyQuery {
          entries(relatedTo: "${card.id}") {
            id
          }
        }`

        let result = null
        try {
          result = await axios({
            method: 'POST',
            url: this.apiUrl,
            data: {
              query
            },
            headers: {
              Authorization: `Bearer ${this.bearer}`
            }
          })

          const {data} = result.data
          const allChallenges = data.entries.map(el => el.id)

          return {
            ...card,
            challenges: allChallenges
          }
        } catch (error) {
          console.error(error)
        }
        return result
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.type-page {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .page-main {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $light;
    padding: 56px 16px 80px;

    .main-text {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 145%;
      text-align: center;
      color: $dark;
      margin: 0 0 32px;
    }
  }
}
</style>
