<template>
  <section class="challenge-chrono" :class="color">
    <p class="chrono-heading">Lancer le chrono !</p>
    <p class="chrono-time">{{ time }}</p>
    <section class="chrono-buttons">
      <!-- Restart -->
      <svg @click.prevent="reset" :class="{beginning: time === '00:00.00'}" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 34"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.937 14.646H1.794c-1.422 0-2.014 1.372-1.72 2.353l5.693 10.436c.687.89 1.856.89 2.73-.351l5.693-10.085c.296-1.223-.295-2.353-1.72-2.353H9.69c1.033-5.731 5.824-9.41 11.56-9.41 6.487 0 11.763 5.276 11.763 11.763 0 6.488-4.704 11.764-11.764 11.764v4.707c9.411 0 16.47-7.388 16.47-16.47C37.72 7.915 30.33.528 21.25.528c-8.306 0-15.198 5.906-16.312 14.117" fill="#89969F"/></svg>
      <!-- Start -->
      <svg v-if="!running" @click.prevent="start" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.3335 19.9987c0-9.2 7.4667-16.66667 16.6667-16.66667S36.6668 10.7987 36.6668 19.9987s-7.4666 16.6667-16.6666 16.6667S3.3335 29.1987 3.3335 19.9987zm13.3332-5.8323V25.833c0 .6834.7833 1.0834 1.3333.6667l7.7833-5.8333c.45-.3334.45-1 0-1.3334L18 13.4997c-.55-.4167-1.3333-.0167-1.3333.6667z" fill="#702784"></path></svg>
      <!-- Stop -->
      <svg v-else @click.prevent="stop" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" id="yui_3_17_2_1_1628791800055_22"><path d="M19.9202 37.1592c-9.4 0-17.07962-7.6796-17.07962-17.0796C2.84058 10.6796 10.5202 3 19.9202 3c9.4 0 17.0796 7.6796 17.0796 17.0796 0 9.4-7.6408 17.0796-17.0796 17.0796zm0-30.9188c-7.6408 0-13.84082 6.2-13.84082 13.8408S12.2794 33.922 19.9202 33.922c7.6408 0 13.8408-6.2 13.8408-13.8408-.0016-7.6408-6.2016-13.8408-13.8408-13.8408z" fill="#702784" id="yui_3_17_2_1_1628791800055_21"></path><path d="M17.879 14.6408v10.8408c0 .8-.6406 1.4797-1.4796 1.4797-.8407 0-1.4797-.6406-1.4797-1.4797V14.6408c0-.8.6406-1.4797 1.4797-1.4797.8406-.0015 1.4796.6797 1.4796 1.4797zM24.9199 14.6408v10.8408c0 .8-.6406 1.4797-1.4797 1.4797-.8 0-1.4797-.6406-1.4797-1.4797l-.0015-10.8408c0-.8.6406-1.4797 1.4797-1.4797.8406.0391 1.4812.6797 1.4812 1.4797z"></path></svg>
    </section>
  </section>
</template>

<script>
export default {
  props: {
    color: String
  },
  data() {
    return {
      time: '00:00.00',
      timeBegan: null,
      timeStopped: null,
      stoppedDuration: 0,
      started: null,
      running: false
    }
  },
  methods: {
    start() {
      if (this.running) return

      if (this.timeBegan === null) {
        this.reset()
        this.timeBegan = new Date()
      }

      if (this.timeStopped !== null) {
        this.stoppedDuration += new Date() - this.timeStopped
      }

      this.started = setInterval(this.clockRunning, 10)
      this.running = true
    },
    stop() {
      this.running = false
      this.timeStopped = new Date()
      clearInterval(this.started)
    },
    reset() {
      this.running = false
      clearInterval(this.started)
      this.stoppedDuration = 0
      this.timeBegan = null
      this.timeStopped = null
      this.time = '00:00.00'
    },
    clockRunning() {
      const currentTime = new Date()
      const timeElapsed = new Date(
        currentTime - this.timeBegan - this.stoppedDuration
      )
      const min = timeElapsed.getUTCMinutes()
      const sec = timeElapsed.getUTCSeconds()
      const ms = timeElapsed.getUTCMilliseconds()

      this.time = `${this.zeroPrefix(min, 2)}:${this.zeroPrefix(sec, 2)}.${this.zeroPrefix(ms, 2)}`
    },
    zeroPrefix(num, digit) {
      let zero = ''
      for (let i = 0; i < digit; i += 1) {
        zero += '0'
      }
      return (zero + num).slice(-digit)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.challenge-chrono {
  width: 100%;
  margin-bottom: 32px;
  padding: 16px;
  background: $lightGray;
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.purple {
    .chrono-heading,
    .chrono-time {
      color: $purple;
    }

    .chrono-buttons svg path {
      fill: $purple;
    }
  }

  &.red {
    .chrono-heading,
    .chrono-time {
      color: $red;
    }

    .chrono-buttons svg path {
      fill: $red;
    }
  }

  .chrono-heading {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    text-align: center;
    margin: 0 0 8px;
  }

  .chrono-time {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 155%;
    text-align: center;
    padding: 0 16px;
    background: $light;
    border-radius: 8px;
    margin: 0 0 20px;
  }

  .chrono-buttons {
    display: flex;
    align-items: center;

    svg {
      height: 40px;
      margin: 0 10px;
      cursor: pointer;

      &:first-child {
        height: 34px;
      }

      &.beginning {
        opacity: 0.5;
      }
    }
  }
}
</style>
