<template>
  <div class="home-page">
    <SliderOnBoarding
      v-if="
        user != null
          ? user.jasInfo.jasOnboardingDone == null ||
            user.jasInfo.jasOnboardingDone == false
          : false
      "
    />
    <section class="home-hero">
      <video
        v-if="video"
        :src="video"
        class="video"
        loop
        muted
        autoplay
        playsinline
      ></video>
      <div class="video-overlay"></div>
      <img
        src="https://res.cloudinary.com/agence-hoffman/image/upload/v1631148496/rseq_jas/animated_logo.gif"
        alt="JAS"
        class="hero-logo"
      />
      <div class="hero-heading" v-html="heading"></div>
      <li v-if="!user" class="hero-buttons">
        <router-link to="/inscription" class="button"> S'inscrire </router-link>
        <router-link to="/connexion" class="link"> Se connecter </router-link>
      </li>
    </section>
    <section class="home-main">
      <div class="main-text" v-html="introText"></div>
      <!-- Physical Activity -->
      <h3 class="main-heading">Activité physique</h3>
      <SportCards color="red" :cards="physicalActivity" />
      <!-- Physical Activity -->
      <h3 class="main-heading margin-top">Sport</h3>
      <SportCards color="purple" :cards="sports" />
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import SportCards from '@/components/SportCards.vue'
import SliderOnBoarding from '@/components/SliderOnBoarding.vue'

export default {
  components: { SportCards, SliderOnBoarding },
  data() {
    return {
      heading: '',
      logo: '',
      video: null,
      introText: '',
      physicalActivity: [
        {
          name: 'Défis',
          image: '',
          url: '/sport/activite-physique/defis',
          typeId: 218,
          id: 1928,
        },
        {
          name: 'Entraînements',
          image: '',
          url: '/sport/activite-physique/entrainements',
          typeId: 219,
          id: 1928,
        },
      ],
      sports: [],
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('app', [
      'appTitle',
      'seoDescription',
      'seoImage',
      'apiUrl',
      'bearer',
    ]),
  },
  async mounted() {
    const query = `query MyQuery {
        entry(slug: "home") {
          ... on home_home_Entry {
            homeHero {
              ... on homeHero_BlockType {
                heading
                logo {
                  url
                }
                video {
                  url
                }
              }
            }
            homeIntro
            homePhysicalActivity {
              ... on homePhysicalActivity_BlockType {
                id
                imageChallenges {
                  url(format: "webp", width: 700, height: 500, mode: "crop", quality: 100)
                }
                imageWorkouts {
                  url(format: "webp", width: 700, height: 500, mode: "crop", quality: 100)
                }
              }
            }
          }
        }
        categories(group: "sports", level: 1) {
          title
          slug
          id
          ... on sports_Category {
            catImage {
              url(format: "webp", width: 700, height: 500, mode: "crop", quality: 100)
            }
          }
        }
      }`

    try {
      const result = await axios({
        method: 'POST',
        url: this.apiUrl,
        data: {
          query,
        },
        headers: {
          Authorization: `Bearer ${this.bearer}`,
        },
      })

      const { entry, categories } = result.data.data

      // Hero Banner
      if (entry.homeHero.length) {
        const homeHero = entry.homeHero[0]
        this.heading = homeHero.heading

        if (homeHero.logo.length) {
          this.logo = homeHero.logo[0].url
        }

        if (homeHero.video.length) {
          this.video = homeHero.video[0].url
        }
      }

      this.introText = entry.homeIntro

      // Physical Activity
      if (entry.homePhysicalActivity.length) {
        const physicalActivity = entry.homePhysicalActivity[0]

        if (physicalActivity.imageChallenges.length) {
          this.physicalActivity[0].image =
            physicalActivity.imageChallenges[0].url
        }

        if (physicalActivity.imageWorkouts.length) {
          this.physicalActivity[1].image = physicalActivity.imageWorkouts[0].url
        }
      }
      this.checkChallengeDone('physicalActivity')

      // Sports
      const sportCategories = categories.filter(
        (el) => el.slug !== 'activite-physique'
      )
      if (sportCategories.length) {
        this.sports = sportCategories.map((el) => {
          let image = ''
          if (el.catImage.length) {
            image = el.catImage[0].url
          }
          return {
            id: el.id,
            name: el.title,
            image,
            url: `/sport/${el.slug}`,
            challenges: null,
          }
        })
        this.checkChallengeDone('sports')
      }
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    async checkChallengeDone(type) {
      if (type === 'sports') {
        this.sports = await Promise.all(
          this.sports.map(async (card) => {
            const query = `query MyQuery {
            entries(relatedTo: "${card.id}", section: "sports") {
              id
            }
          }`

            let result = null
            try {
              result = await axios({
                method: 'POST',
                url: this.apiUrl,
                data: {
                  query,
                },
                headers: {
                  Authorization: `Bearer ${this.bearer}`,
                },
              })

              const { data } = result.data
              const allChallenges = data.entries.map((el) => el.id)

              return {
                ...card,
                challenges: allChallenges,
              }
            } catch (error) {
              console.error(error)
            }
            return result
          })
        )
      } else {
        this.physicalActivity = await Promise.all(
          this.physicalActivity.map(async (card) => {
            const query = `query MyQuery {
            entries(section: "physicalActivity", relatedToAll: [${card.id} , ${card.typeId}]) {
              id
            }
          }`

            let result = null
            try {
              result = await axios({
                method: 'POST',
                url: this.apiUrl,
                data: {
                  query,
                },
                headers: {
                  Authorization: `Bearer ${this.bearer}`,
                },
              })

              const { data } = result.data
              const allChallenges = data.entries.map((el) => el.id)

              return {
                ...card,
                challenges: allChallenges,
              }
            } catch (error) {
              console.error(error)
            }
            return result
          })
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.home-page {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .home-hero {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px 60px;
    position: relative;
    overflow: hidden;

    @media (max-width: $mobileBreakpoint) {
      margin-bottom: 32px;
    }

    .video {
      position: absolute;
      inset: 0;
      z-index: -1;
    }

    .video-overlay {
      position: absolute;
      inset: 0;
      z-index: -1;
      background-color: rgba(163, 163, 163, 0.6);
    }

    .hero-logo {
      width: 100%;
      max-width: 400px;
    }

    .hero-heading {
      color: $dark;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 165%;
      text-align: center;
      margin: 16px 0 0;
    }

    .hero-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;

      .button {
        @extend .button;
      }

      .link {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 145%;
        text-align: center;
        text-decoration-line: underline;
        color: $dark;
        margin-top: 16px;
      }
    }
  }

  .home-main {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $light;
    padding: 56px 16px 80px;

    .main-text {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 145%;
      text-align: center;
      color: $dark;
      max-width: 500px;
      margin: 0 0 56px;
    }

    .main-heading {
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 145%;
      margin: 0 0 25px;
      text-align: center;

      &.margin-top {
        margin-top: 60px;
      }
    }
  }
}
</style>
