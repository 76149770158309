<template>
  <div>
    <div class="modal-wrapper">
      <div class="close" @click="$emit('close')"></div>
      <div class="main-content">
        <img class="app-logo" src="@/assets/logo.png" />
        <h3 class="title">Installer {{ appTitle }}</h3>
        <p class="description">
          Installez cette application sur votre écran d'accueil pour un accès rapide et facile lorsque vous êtes en déplacement.
        </p>
      </div>

      <div class="footer">
        Appuyez simplement sur
        <img
          class="apple-navigation-action-img"
          src="@/assets/img/android-navigation-action.png"
        />
        <br/>puis "Ajouter à l'écran d'accueil"
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.modal-wrapper {
  box-shadow: 0px 0px 50px 0px rgba(#656565, 0.25);
  margin: 30px;
  max-width: 330px;
  background: $light;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 21px;
    height: 21px;
    opacity: 0.3;
  }

  .close:hover {
    opacity: 1;
  }

  .close:before,
  .close:after {
    position: absolute;
    left: 8px;
    content: ' ';
    height: 22px;
    width: 2px;
    background-color: #333;
  }

  .close:before {
    transform: rotate(45deg);
  }

  .close:after {
    transform: rotate(-45deg);
  }

  .main-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .app-logo {
      max-width: 50px;
      margin-bottom: 10px;
    }

    .title {
      margin: 5px 0;
      text-align: center;
    }

    .description {
      margin: 5px 0;
      text-align: center;
    }
  }

  .footer {
    //display: flex;
    //justify-content: center;
    //align-items: center;
    text-align: center;
    padding: 10px;
    background-color: #f9f9f9;
    font-size: 12px;

    .apple-navigation-action-img {
      max-width: 15px;
      margin: 0 10px;
    }
  }
}
</style>
