export default {
  appTitle: process.env.VUE_APP_TITLE,
  appShortTitle: process.env.VUE_APP_SHORT_TITLE,
  apiUrl: process.env.VUE_APP_API_URL,
  bearer: process.env.VUE_APP_BEARER,
  seoDescription: 'Jeune actif au secondaire aspire à créer une communauté virtuelle visant à outiller les jeunes âgés de 13 à 17 ans dans l’atteinte d’un mode de vie sain et actif.',
  seoImage: 'https://res.cloudinary.com/agence-hoffman/image/upload/v1629902114/rseq_jas/seoImage-v3.png',
  networkOnLine: true,
  SWRegistrationForNewContent: null,
  showAddToHomeScreenModalForApple: false,
  showAddToHomeScreenModalForAndroid: false,
  refreshingApp: false,
  sportName: undefined
}
