<template>
  <div class="sport-page">
    <HeroBanner :color="hero.color" :heading="sportName" />
    <section class="page-main">
      <h3 class="main-heading">Fais ton choix</h3>
      <section class="main-cards">
        <router-link :to="`/sport/${$route.params.sportName}/defis`" class="card-item" :class="hero.color">
          <svg class="card-icon" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="#B6B0D6"><path d="M16 9.254a8.972 8.972 0 108.972 8.972A8.982 8.982 0 0016 9.254zm0 16.922a7.95 7.95 0 117.95-7.95 7.959 7.959 0 01-7.95 7.95z"/><path d="M24.68 11.477l.29-.261.374.416a.44.44 0 00.618.033l.61-.547a.44.44 0 00.033-.618l-1.501-1.673a.44.44 0 00-.619-.034l-.609.547a.439.439 0 00-.034.619l.347.385-.277.248a10.972 10.972 0 00-6.736-3.303V6.1h1.472a.35.35 0 00.35-.35v-2.4a.35.35 0 00-.35-.35h-5.015a.35.35 0 00-.35.35v2.4a.35.35 0 00.35.35h1.399v1.169a11.014 11.014 0 109.648 4.208zM16 28.204a9.977 9.977 0 119.977-9.978A9.989 9.989 0 0116 28.204z"/><path d="M21.346 14.92a.413.413 0 00-.578-.083l-3.63 2.705a1.302 1.302 0 10.472.677l3.652-2.72a.413.413 0 00.084-.579z"/></g><defs><clipPath id="clip0"><path fill="#fff" transform="translate(5 3)" d="M0 0h22v26.225H0z"/></clipPath></defs></svg>
          <p class="card-heading">Défis</p>
          <p class="card-text">Montre ce que tu as dans le ventre</p>
        </router-link>
        <router-link :to="`/sport/${$route.params.sportName}/entrainements`" class="card-item" :class="hero.color">
          <svg class="card-icon" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="#B6B0D6"><path d="M15.825 9.222a.661.661 0 01-.534-.27l-1.849-2.51a.663.663 0 111.066-.786l1.849 2.51a.662.662 0 01-.532 1.056zM18.927 8.891a.663.663 0 01-.655-.57l-.396-2.84a.661.661 0 01.824-.752.662.662 0 01.488.568l.396 2.84a.66.66 0 01-.657.754zM21.834 8.297a.662.662 0 01-.628-.872l.99-2.972a.665.665 0 111.263.419l-.991 2.972a.662.662 0 01-.634.453zM30.055 12.607l-.793-3.787a.661.661 0 00-.759-.518l-7.22 1.234a.661.661 0 00-.55.69l.021.335c.101 1.49.07 2.015-1.23 2.232a.975.975 0 01-.794-.171c-.603-.452-.853-1.584-.905-1.98a.662.662 0 00-.773-.577l-7.044 1.233a.662.662 0 00.228 1.304l6.405-1.12c.169.66.533 1.626 1.29 2.192a2.301 2.301 0 001.81.423c2.386-.399 2.43-2.042 2.352-3.36l6.003-1.024.56 2.68c-.746.403-2.343 1.268-3.954 2.171-4.584 2.57-4.812 2.936-4.948 3.155-.136.22-.208.526-.335 1.083-.457 2.01-1.408 6.193-6.012 6.936a6.493 6.493 0 01-5.49-1.518l-.039-.032A6.167 6.167 0 015.763 20c-.165-1.931.65-3.99.658-4.01a.665.665 0 00-1.232-.497 12.304 12.304 0 00-.731 3.052c-.527.036-1.496.212-2.054.967-.334.45-.469 1.037-.391 1.695.07.59.313 1.063.703 1.367.38.273.84.41 1.307.391a4.123 4.123 0 001.142-.175 7.212 7.212 0 001.896 2.448 7.805 7.805 0 005.181 1.949c.47.004.939-.041 1.399-.137 5.466-.894 6.59-5.834 7.07-7.945.055-.244.122-.54.165-.663.587-.552 4.91-3.004 8.843-5.106a.663.663 0 00.336-.728zM3.313 21.454a.587.587 0 01-.211-.44c-.041-.353.05-.525.122-.622.252-.344.807-.478 1.203-.52 0 .079.01.157.016.236.04.488.127.972.258 1.444-.436.1-1.064.153-1.388-.098z"/><path d="M12.355 24.246a5.087 5.087 0 115.087-5.086 5.093 5.093 0 01-5.087 5.086zm0-8.847a3.762 3.762 0 100 7.523 3.762 3.762 0 000-7.524zM7.636 14.095a.65.65 0 100-1.298.65.65 0 000 1.298z"/></g><defs><clipPath id="clip0"><path fill="#fff" transform="translate(2 4)" d="M0 0h28.07v23.177H0z"/></clipPath></defs></svg>
          <p class="card-heading">Entraînements</p>
          <p class="card-text">Améliore tes aptitudes</p>
        </router-link>
      </section>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import axios from 'axios'
// eslint-disable-next-line import/extensions
import HeroBanner from '@/components/HeroBanner'

export default {
  components: { HeroBanner },
  data() {
    return {
      hero: {
        color: 'purple'
      }
    }
  },
  computed: mapState('app', ['appTitle', 'apiUrl', 'bearer', 'sportName']),
  async mounted() {
    const { sportName } = this.$route.params

    if (sportName === 'activite-physique') {
      this.hero.color = 'red'
    }

    const query =
      `query MyQuery {
          category(group: "sports", level: 1, slug: "${sportName}") {
            title
          }
        }`

    try {
      const result = await axios({
        method: 'POST',
        url: this.apiUrl,
        data: {
          query
        },
        headers: {
          'Authorization': `Bearer ${this.bearer}`
        }
      })

      const { category } = result.data.data

      this.setSportName(category.title)
    } catch (error) {
      console.error(error)
    }
  },
  methods: mapMutations('app', ['setSportName'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.sport-page {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .page-main {
    flex: 1;
    width: 100%;
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-heading {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 145%;
      text-align: center;
      color: #000000;
      margin: 0 0 32px;
    }

    .main-cards {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: $mobileBreakpoint) {
        flex-direction: column;
      }

      .card-item {
        width: calc((100% - 24px) / 2);
        max-width: 330px;
        background-color: $purple;
        border-radius: 16px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 24px;
        transition: background-color ease-out .2s;

        &.red {
          background-color: $red;
        }

        @media (max-width: $mobileBreakpoint) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 24px;
        }

        &:last-child {
          margin-right: 0;

          @media (max-width: $mobileBreakpoint) {
            margin-bottom: 0;
          }
        }

        &:hover {
          background-color: rgba($purple, .75);

          &.red {
            background-color: rgba($red, .75);
          }
        }

        .card-icon {
          height: 32px;
        }

        .card-heading {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 145%;
          text-align: center;
          color: $light;
          margin: 0 0 4px;
        }

        .card-text {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 145%;
          text-align: center;
          color: $light;
          margin: 0;
        }
      }
    }
  }
}
</style>
