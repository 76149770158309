import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import { isNil } from 'lodash'
import Test from '@/views/Test.vue'
import Home from '@/views/Home.vue'
import CheckLogin from '@/views/CheckLogin.vue'
import SportPage from '@/views/SportPage.vue'
import TypePage from '@/views/TypePage.vue'
import CategoryPage from '@/views/CategoryPage.vue'
import ChallengePage from '@/views/ChallengePage.vue'
import FAQ from '@/views/FAQ.vue'
import Ranking from '@/views/Ranking.vue'
import Contact from '@/views/Contact.vue'
import Glossary from '@/views/Glossary.vue'
import GlossaryPage from '@/views/GlossaryPage.vue'
import BlogListing from '@/views/BlogListing.vue'
import BlogDetails from '@/views/BlogDetails.vue'
// eslint-disable-next-line import/extensions
import store from '@/store'

Vue.use(Router)
Vue.use(Meta)

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/test',
      name: 'home',
      component: Test,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/lexique',
      name: 'glossary',
      component: Glossary,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/check-login',
      name: 'check-login',
      component: CheckLogin,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/inscription',
      name: 'register',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/Register.vue'
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/connexion',
      name: 'login',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/Login.vue'
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/mon-profil',
      name: 'my-profile',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/Profile.vue'
        ),
      meta: {
        authNotRequired: false
      }
    },
    {
      path: '/favoris',
      name: 'favoris',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/FavoritesPage.vue'
        ),
      meta: {
        authNotRequired: false
      }
    },
    {
      path: '/sport/:sportName',
      name: 'sport-page',
      component: SportPage,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/sport/:sportName/:typeName',
      name: 'sport-type',
      component: TypePage,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/sport/:sportName/:typeName/:categoryName',
      name: 'sport-category',
      component: CategoryPage,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/sport/:sportName/:typeName/:categoryName/:challengeName',
      name: 'sport-challenge',
      component: ChallengePage,
      meta: {
        authNotRequired: false
      }
    },
    {
      path: '/lexique/:glossaryName',
      name: 'glossary-page',
      component: GlossaryPage,
      meta: {
        authNotRequired: false
      }
    },
    {
      path: '/blog',
      name: 'blog-listing',
      component: BlogListing,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/blog/:articleName',
      name: 'blog-details',
      component: BlogDetails,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/faq',
      name: 'faq',
      component: FAQ,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/classement',
      name: 'ranking',
      component: Ranking,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
      meta: {
        authNotRequired: true
      }
    },
    { path: '*', redirect: '/' }
  ]
})

/**
 * Handle user redirections
 */
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  if (
    !(to.meta && to.meta.authNotRequired) &&
    isNil(store.state.authentication.user)
  ) {
    const path =
      store.state.authentication.user === null ? '/connexion' : '/check-login'
    return next(`${path}?redirectUrl=${to.path}`)
  }
  next()
})

export default router
