import firebase from 'firebase/app'
import { isNil } from 'lodash'

// eslint-disable-next-line import/extensions
import store from '@/store'

firebase.auth().onAuthStateChanged(firebaseUser => {
  const actionToDispatch = isNil(firebaseUser) ? 'logout' : 'login'
  store.dispatch(`authentication/${actionToDispatch}`, firebaseUser)
})
