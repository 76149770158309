import { isNil } from 'lodash'
import firebase from 'firebase'
// eslint-disable-next-line import/extensions
import router from '@/router'
// eslint-disable-next-line import/extensions
import { createNewUserFromFirebaseAuthUser, isProfileComplete } from '@/misc/helpers'
// eslint-disable-next-line import/extensions
import UsersDB from '@/firebase/users-db'

export default {
  /**
   * Callback fired when user login
   */
  login: async ({ commit, state }, firebaseAuthUser) => {
    const userFromFirebase = await new UsersDB().read(firebaseAuthUser.uid)

    const user = isNil(userFromFirebase)
      ? await createNewUserFromFirebaseAuthUser(firebaseAuthUser, {
          firstname: state.tempFirstname,
          lastname: state.tempLastname
        })
      : userFromFirebase

    commit('setUser', user)

    if (!isProfileComplete(user)) {
      router.push('/mon-profil')
    }
  },

  /**
   * Callback fired when user logout
   */
  logout: ({ commit }) => {
    commit('setUser', null)

    return firebase
      .auth()
      .signOut()
  },

  updateUser: async ({ commit }, userInfo) => {
    await new UsersDB().update(userInfo)

    const userUpdated = await new UsersDB().read(userInfo.id)

    commit('setUser', userUpdated)
  }
}
