<template>
  <section class="card-list" :class="color">
    <div
      v-for="(card, index) in cards"
      :key="index"
      class="card-item"
      :class="{ 'is-done': card.done }"
      @click.stop="goTo($event, card.url)"
    >
      <span v-if="card.number" class="card-number" :class="card.difficulty">{{
        card.number
      }}</span>

      <button
        type="button"
        class="card-favorite"
        @click.stop="onFavorite($event, card)"
      >
        <svg
          v-if="!isFavorite(card)"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
        >
          <path
            style="text-indent:0;text-transform:none;direction:ltr;block-progression:tb;baseline-shift:baseline;color:#000;enable-background:accumulate"
            d="M32.28 969.373c-4.686 0-9.376 1.916-12.938 5.75-7.124 7.669-7.116 19.982 0 27.657l29.907 32.25a1 1 0 0 0 1.468 0c9.983-10.746 19.955-21.473 29.938-32.219 7.124-7.668 7.124-19.957 0-27.625-7.124-7.669-18.751-7.669-25.875 0l-4.781 5.125-4.813-5.188c-3.562-3.834-8.22-5.75-12.906-5.75zm0 1.97c4.135 0 8.27 1.713 11.469 5.155l5.53 5.97a1 1 0 0 0 1.47 0l5.5-5.938c6.395-6.885 16.542-6.884 22.937 0s6.395 18.022 0 24.906c-9.737 10.482-19.45 20.987-29.187 31.469l-29.188-31.5c-6.39-6.892-6.395-18.023 0-24.907 3.198-3.442 7.333-5.156 11.469-5.156z"
            overflow="visible"
            transform="translate(0 -952.362)"
          />
        </svg>

        <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
          <path
            :class="getColor(card)"
            d="M20.922 976.649c-6.566 7.048-6.56 18.391 0 25.447l29.06 31.266 29.093-31.23c6.567-7.049 6.567-18.398 0-25.447-6.566-7.05-17.139-7.05-23.705 0l-5.354 5.747-5.388-5.783c-6.566-7.049-17.14-7.049-23.706 0z"
            style="color:#000;enable-background:accumulate"
            overflow="visible"
            transform="translate(0 -952.362)"
          />
        </svg>
      </button>

      <div class="image-container">
        <img
          v-if="card.image"
          :src="card.image"
          :alt="card.name"
          class="card-image"
          loading="lazy"
        />
        <div class="image-overlay"></div>
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 73">
          <path
            d="M35.327.012c-2.642.066-5.295.42-7.904 1.073C8.027 5.94-3.771 25.66 1.095 45.015c4.867 19.353 24.6 31.128 43.994 26.271 19.394-4.856 31.193-24.548 26.326-43.902a2.783 2.783 0 0 0-1.207-1.925 2.794 2.794 0 0 0-4.027 1.022 2.778 2.778 0 0 0-.141 2.265c4.132 16.436-5.848 33.022-22.317 37.146-16.47 4.124-33.12-5.805-37.252-22.241C2.339 27.216 12.319 10.6 28.788 6.476a30.706 30.706 0 0 1 25.541 4.96 2.794 2.794 0 0 0 4.123-.418 2.78 2.78 0 0 0-.838-4.048A36.311 36.311 0 0 0 35.327.012ZM77.054 1.81c-.723.044-1.4.365-1.889.898-9.892 10.329-25.89 27.905-36.293 38.856L25.1 29.444a2.804 2.804 0 0 0-2.742-.564 2.79 2.79 0 0 0-.977 4.74l15.807 13.918a2.791 2.791 0 0 0 3.864-.145c10.393-10.85 27.854-30.104 38.124-40.828a2.784 2.784 0 0 0-.065-4.014 2.793 2.793 0 0 0-2.056-.741Z"
            fill="#68D09E"
          />
        </svg>
      </div>
      <div class="card-content">
        <p class="card-text" :class="`color-${getColor(card)}`">
          {{ card.name }}
        </p>
        <div v-if="card.rate" class="card-rating" :class="`color-${color}`">
          <div class="rating-stars" :stars="card.rate.average">
            <svg
              v-for="i in 5"
              :key="i"
              version="1.1"
              width="16"
              class="star rating"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 24 24"
              xml:space="preserve"
            >
              <path
                class="outline"
                d="M12,4.2L14.5,9l0.2,0.5l0.5,0.1l5.5,0.8L16.8,14l-0.4,0.4l0.1,0.5l1,5.3l-5-2.5L12,17.5l-0.4,0.2l-5,2.5L7.5,15l0.1-0.5 L7.2,14l-4-3.7l5.5-0.8l0.5-0.1L9.5,9L12,4.2 M11.9,2L8.6,8.6L1,9.7l5.5,5.1L5.2,22l6.8-3.4l6.8,3.4l-1.3-7.2L23,9.6l-7.6-1L11.9,2 L11.9,2z"
              />
              <polygon
                class="full"
                points="18.8,22 12,18.6 5.2,22 6.5,14.8 1,9.7 8.6,8.6 11.9,2 15.4,8.6 23,9.6 17.5,14.7"
              />
            </svg>
          </div>
          <p class="rating-votes">
            {{ `${card.rate.nbVotes} vote${card.rate.nbVotes > 1 ? 's' : ''}` }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { getChallengeRate } from '../misc/helpers'

export default {
  props: {
    color: String,
    cards: Array
  },
  computed: {
    ...mapState('authentication', ['user'])
  },
  mounted() {
    setTimeout(() => {
      this.cards.forEach(async card => {
        card.rate = await getChallengeRate(card.id)
      })
    }, 250)
  },
  methods: {
    onFavorite(e, card) {
      this.$emit('favorite', card)
    },
    goTo(e, url) {
      if (e.target.tagName === 'BUTTON') {
        return
      }

      this.$router.push(url)
    },
    isFavorite(card) {
      if (!this.user || !this.user.jasInfo.favorites) {
        return false
      }

      return this.user.jasInfo.favorites.includes(card.id)
    },
    getColor(card) {
      return card.sectionHandle === 'sports' ? 'purple' : 'red'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.card-list {
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;

  @media (max-width: $tabletBreakpoint) {
    max-width: 700px;
  }

  @media (max-width: $mobileBreakpoint) {
    max-width: 500px;
  }

  .card-item {
    position: relative;
    width: calc((100% - 48px) / 3);
    margin: 0 24px 24px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $light;
    border: 1px solid $lightGray;
    filter: drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.03));
    cursor: pointer;

    &:nth-child(3n + 3) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: $tabletBreakpoint) {
      width: calc((100% - 24px) / 2);

      &:nth-child(3n + 3) {
        margin-right: 24px;
      }

      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }

    @media (max-width: $mobileBreakpoint) {
      width: 100%;
      margin-right: 0;

      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }

    &.is-done {
      .image-container {
        &::before,
        svg {
          display: block;
        }
      }
    }

    .card-favorite {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 5;
      width: 36px;
      height: 36px;
      background: $lightGray;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      z-index: 1;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 26px;
        transition: all 0.2s ease-in-out;

        path {
          &.purple {
            fill: $purple;
          }

          &.red {
            fill: $red;
          }
        }
      }

      &:hover {
        svg {
          transform: translate(-50%, -50%) scale(1.1);
        }
      }
    }

    .card-number {
      position: absolute;
      top: 8px;
      left: 8px;
      width: 32px;
      height: 32px;
      border-radius: 32px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      text-align: center;
      color: $light;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $gray;
      z-index: 2;

      &.easy {
        background-color: $easy;
      }

      &.medium {
        background-color: $medium;
        color: $dark;
      }

      &.hard {
        background-color: $hard;
      }
    }

    .image-container {
      width: 100%;
      background-color: #c4c4c4;
      position: relative;

      &::before {
        content: '';
        display: none;
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
      }

      .card-image {
        width: 100%;
        height: 100%;
        object-position: center center;
        object-fit: cover;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        display: none;
        width: 80px;
      }
    }

    .card-text {
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 145%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 0 0 22px;
    }

    .card-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 16px;
    }

    .card-rating {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;

      &.color-purple {
        .rating-stars .star path,
        .rating-stars .star polygon {
          stroke: $purple;
        }

        .rating-stars[stars] {
          .star path,
          .star polygon {
            fill: $purple;
          }
        }
      }

      &.color-red {
        .rating-stars .star path,
        .rating-stars .star polygon {
          stroke: $red;
        }

        .rating-stars[stars] {
          .star path,
          .star polygon {
            fill: $red;
          }
        }
      }
    }

    .rating-stars {
      cursor: pointer;
      display: flex;
      margin-right: 16px;

      .star {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }

        polygon,
        path {
          fill: $light;
        }
      }

      &[stars='0'] {
        .star path,
        .star polygon {
          fill: $light !important;
        }
      }

      &[stars='1'] {
        .star:nth-child(1) ~ .star path,
        .star:nth-child(1) ~ .star polygon {
          fill: $light;
        }
      }

      &[stars='2'] {
        .star:nth-child(2) ~ .star path,
        .star:nth-child(2) ~ .star polygon {
          fill: $light;
        }
      }

      &[stars='3'] {
        .star:nth-child(3) ~ .star path,
        .star:nth-child(3) ~ .star polygon {
          fill: $light;
        }
      }

      &[stars='4'] {
        .star:nth-child(4) ~ .star path,
        .star:nth-child(4) ~ .star polygon {
          fill: $light;
        }
      }

      &[stars='5'] {
        .star:nth-child(5) ~ .star path,
        .star:nth-child(5) ~ .star polygon {
          fill: $light;
        }
      }
    }

    .rating-votes {
      margin: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 155%;
      color: $gray;
      font-family: $fontFurura;
    }
  }
}
</style>
