<template>
  <section class="blog-details-page">
    <HeroBanner color="blue" :heading="heading" />

    <div class="page-main">
      <div class="page-container">
        <h3 v-if="title" class="main-heading">{{ title }}</h3>

        <div class="blog-content" v-if="thumbnail && content">
          <div class="content-image" v-if="thumbnail">
            <img :src="thumbnail" />
          </div>

          <section class="content-section" v-for="(section, index) in content" :key="index">
            <div v-if="section.__typename === 'blogDetails_image_BlockType'" class="content-image">
              <img :src="section.image[0].url" />
            </div>

            <div v-if="section.__typename === 'blogDetails_text_BlockType'" class="content-paragraph">
              <h4 v-if="section.heading !== ''" class="content-heading">{{ section.subtitle }}</h4>

              <div class="content-text" v-html="section.text"></div>
            </div>

            <div v-if="section.__typename === 'blogDetails_video_BlockType'" class="content-video" v-html="section.video[0].embeddedAsset.html"></div>

            <a v-if="section.__typename === 'blogDetails_link_BlockType'" :href="section.linkUrl" class="content-link" target="_blank">
              <svg
                class="link-icon"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 40 40"
              >
                <path
                  d="M15.001 5V1.667a1.666 1.666 0 1 0-3.333 0V5a1.666 1.666 0 1 0 3.333 0ZM1.667 15H5a1.666 1.666 0 1 0 0-3.334H1.667a1.666 1.666 0 1 0 0 3.333ZM7.842 5.49 6.176 3.825a1.667 1.667 0 0 0-2.35 2.35l1.666 1.667a1.667 1.667 0 0 0 2.35-2.35ZM26.666 40a1.667 1.667 0 0 0 1.667-1.667V35A1.666 1.666 0 1 0 25 35v3.333A1.669 1.669 0 0 0 26.666 40ZM40 26.666A1.669 1.669 0 0 0 38.332 25h-3.334a1.666 1.666 0 1 0 0 3.333h3.334a1.667 1.667 0 0 0 1.666-1.667ZM33.825 36.175a1.667 1.667 0 0 0 2.35-2.35l-1.666-1.667a1.667 1.667 0 0 0-2.35 2.35l1.666 1.667ZM1.667 28.416a9.93 9.93 0 0 0 9.917 9.917 9.825 9.825 0 0 0 7.008-2.908l5.166-5.166a9.911 9.911 0 0 0 0-14.018 1.666 1.666 0 1 0-2.358 2.35 6.598 6.598 0 0 1 0 9.317l-5.166 5.167a6.592 6.592 0 0 1-9.308-9.317l2.583-2.583a1.667 1.667 0 0 0-2.35-2.35l-2.584 2.583a9.825 9.825 0 0 0-2.908 7.008Z"
                  fill="#3E92CC"
                />
                <path
                  d="M30.49 18.826a1.666 1.666 0 0 0 2.35 2.349l2.583-2.583a9.825 9.825 0 0 0 2.908-7.008 9.93 9.93 0 0 0-9.916-9.917 9.825 9.825 0 0 0-7.008 2.908L16.24 9.742a9.901 9.901 0 0 0 0 14.017c.65.646 1.7.646 2.35 0 .646-.65.646-1.7 0-2.35a6.6 6.6 0 0 1 0-9.317l5.166-5.167a6.59 6.59 0 0 1 9.317 9.317l-2.583 2.584Z"
                  fill="#3E92CC"
                />
              </svg>

              <div class="link-content">
                <span class="link-title">{{ section.linkHeading }}</span>
                <span v-if="section.linkText !== ''" class="link-subtitle">
                  {{ section.linkText }}
                </span>
              </div>
            </a>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import HeroBanner from '@/components/HeroBanner.vue'

export default {
  components: { HeroBanner },
  data() {
    return {
      heading: 'Actualités',
      title: '',
      thumbnail: null,
      content: []
    }
  },
  computed: {
    hero() {
      return {
        heading: `Actualités`
      }
    },
    ...mapState('app', ['appTitle', 'apiUrl', 'bearer'])
  },
  watch: {
    $route(to, from) {
      if (
        to.name === from.name &&
        to.name === 'blog-page' &&
        to.path !== from.path
      ) {
        this.getInfos()
        window.scrollTo(0, 0)
      }
    }
  },
  mounted() {
    this.getInfos()
  },
  methods: {
    async getInfos() {
      const { articleName } = this.$route.params

      const query = `query MyQuery {
        entries(section: "blogNews", slug: "${articleName}") {
          id
          ... on blogNews_default_Entry {
            id
            title
            thumbnail {
              url
            }
            blogDetails {
              __typename
              ... on blogDetails_text_BlockType {
                subtitle
                text
              }
              ... on blogDetails_image_BlockType {
                image {
                  url
                }
              }
              ... on blogDetails_video_BlockType {
                video {
                  embeddedAsset {
                    html
                  }
                }
              }
              ... on blogDetails_link_BlockType {
                linkUrl
                linkHeading
                linkText
              }
            }
          }
        }
      }`

      try {
        const result = await axios({
          method: 'POST',
          url: this.apiUrl,
          data: {
            query
          },
          headers: {
            Authorization: `Bearer ${this.bearer}`
          }
        })

        const { entries } = result.data.data

        this.title = entries[0].title
        this.thumbnail = entries[0].thumbnail[0].url
        this.content = entries[0].blogDetails
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.blog-details-page {
  background: $light;

  .page-main {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 16px 30px;

    .page-container {
      width: 100%;
      max-width: 800px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .main-heading {
      font-size: 24px;
      font-weight: 500;
      font-style: normal;
      line-height: 145%;
      text-align: center;
      margin: 40px 0 32px;
    }

    .blog-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 155%;

      .content-section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .content-image,
      .content-video {
        width: 100%;
        margin: 0 0 32px;
      }

      img,
      iframe,
      video {
        display: block;
        width: 100%;
      }

      .content-link {
        display: inline-flex;
        align-items: center;
        width: 100%;
        max-width: 540px;
        margin: 0 auto 32px;
        padding: 15px 20px;
        border: 1px solid $lightGray;
        border-radius: 16px;
        background: $lightGray;

        svg {
          width: 40px;
          height: auto;
          margin: 0;
        }

        .link-content {
          display: flex;
          flex-direction: column;
          margin: 0 0 0 20px;

          span {
            font-family: $fontFurura;
            font-weight: 500;
            font-style: normal;
            line-height: 155%;
            font-size: 16px;
          }

          .link-title {
            color: $blue2;
          }

          .link-subtitle {
            color: $dark;
          }
        }
      }

      .content-paragraph {
        h4 {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 155%;
          text-transform: uppercase;
          color: #89969f;
          margin: 0;
        }

        & > * {
          margin: 0 0 32px;
        }

        ul li {
          color: #89969f;
        }
      }


    }
  }
}
</style>
