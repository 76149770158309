<template>
  <section class="blog-listing-page">
    <HeroBanner color="blue" :heading="heading" />

    <div class="page-main">
      <h3 class="main-heading">Parcours nos articles.</h3>

      <!-- Blog listing -->
      <BlogCards v-if="blogEntries" :cards="blogEntries" />
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
// eslint-disable-next-line import/extensions
import HeroBanner from '@/components/HeroBanner.vue'
import BlogCards from '@/components/BlogCards.vue'

export default {
  components: { HeroBanner, BlogCards },
  data() {
    return {
      heading: 'Actualités',
      blogEntries: []
    }
  },
  computed: {
    ...mapState('app', ['apiUrl', 'bearer'])
  },
  async mounted() {
    const query = `query MyQuery {
      entries(section: "blogNews") {
        ... on blogNews_default_Entry {
          id
          slug
          title
          thumbnail {
            url
          }
        }
      }
    }
`

    try {
      const result = await axios({
        method: 'POST',
        url: this.apiUrl,
        data: {
          query
        },
        headers: {
          Authorization: `Bearer ${this.bearer}`
        }
      })

      const { entries } = result.data.data

      this.blogEntries = entries
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    scroll(letter) {
      const $target = document.querySelector(`[data-letter="${letter}"]`)

      if ($target) {
        const $menu = document.querySelector('.header')
        const $letters = document.querySelector('.glossary-filters')

        const rect = $target.getBoundingClientRect()
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop

        const destination =
          rect.top + scrollTop - $menu.offsetHeight - $letters.offsetHeight - 16

        window.scrollTo({
          top: destination,
          behavior: 'smooth'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.blog-listing-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  .page-main {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .main-heading {
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    line-height: 145%;
    text-align: center;
    margin: 40px 0 30px;
  }
}
</style>
