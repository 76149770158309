<template>
  <section class="card-list">
    <router-link
      v-for="(card, index) in finalCards"
      :key="index"
      :to="`${card.url}`"
      class="card-item"
    >
      <div class="image-container">
        <img
          v-if="card.image"
          :src="`${card.image}`"
          :alt="card.name"
          class="card-image"
          loading="lazy"
        />
      </div>
      <p :style="`color: ${color}`" class="card-text color-purple">
        {{ card.name }}
      </p>
      <div v-if="card.challenges && user && card.challengesDone != null" class="challenge-achieved-container">
        <p :style="`color:${color}`">
          <span class="challenge-achieved">{{ card.challengesDone }} </span>
          <span class="challenge-total">/{{ card.challenges.length }}</span>
        </p>
        <div class="progress-bar-container">
          <div :style="`background-color:${color}`" class="progress-bar-background"></div>
          <div ref="progressBar" :style="`background-color:${color}; transform: scaleX(${card.ratio})`" class="progress-bar"></div>
        </div>
      </div>
    </router-link>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    color: String,
    cards: Array
  },
  computed: {
    ...mapState('authentication', ['user']),
    finalCards() {
      return this.cards.map(card => {
        const challengesDone = this.getChallengesDone(card.challenges)
        const ratio = challengesDone === 0 ? 0 : challengesDone / card.challenges.length
        return {
          ...card,
          challengesDone,
          ratio
        }
      })
    }
  },
  methods: {
    getChallengesDone(challenges) {
      let allChallengesDone = []
      if (this.user && this.user.jasInfo && this.user.jasInfo.challenges.length && challenges) {
        allChallengesDone = this.user.jasInfo.challenges.filter(el => challenges.includes(el.id))
        if (!allChallengesDone) {
          allChallengesDone = []
        }
      }
      return allChallengesDone.length
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.card-list {
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;

  @media (max-width: $tabletBreakpoint) {
    max-width: 700px;
  }

  @media (max-width: $mobileBreakpoint) {
    max-width: 500px;
  }

  .card-item {
    width: 100%;
    max-width: calc((100% - 48px) / 3);
    margin: 0 24px 24px 0;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $light;
    border: 1px solid $lightGray;
    filter: drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.03));

    &:nth-child(3n + 3) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: $tabletBreakpoint) {
      max-width: calc((100% - 24px) / 2);

      &:nth-child(3n + 3) {
        margin-right: 24px;
      }

      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }

    @media (max-width: $mobileBreakpoint) {
      max-width: 100%;
      margin-right: 0;
      &:nth-child(3n + 3) {
        margin-right: 0;
      }

      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }

    .image-container {
      width: 100%;
      background-color: #c4c4c4;

      .card-image {
        width: 100%;
        height: 100%;
        object-position: center center;
        object-fit: cover;
      }
    }

    .card-text {
      width: 100%;
      padding: 16px 16px 0;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 145%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 0;
    }

    .challenge-achieved-container {
      width: 100%;
      padding: 0 16px;
      
      p {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        text-align: end;
        font-family: $fontFururaCondensed;
        .challenge-total {
          opacity: .5;
        }
      }

      .progress-bar-container {
        position: relative;
        height: 5px;
        border-radius: 3px;
        width: 100%;
        overflow: hidden;

        .progress-bar-background {
          position: absolute;
          inset: 0;
          opacity: .4;
        }
        .progress-bar {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 5px;
          border-radius: 3px;
          transform: scaleX(0);
          transform-origin: left;
          transition: all 1s ease;
          z-index: 2;
        }
        
      }
    }
  }
}
</style>
