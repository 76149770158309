<template>
  <div class="type-page">
    <HeroBanner
      :color="hero.color"
      :heading="hero.heading"
      :breadcrumb="hero.breadcrumb"
    />
    <section class="page-main">
      <div v-if="user != null" :class="`progress ${hero.color}`">
        <p><span class="challenges-done">{{ challengesDone }}</span>/{{ challenges.length }} complétés</p>
        <div class="progress-bar-container">
          <div :style="`background-color: ${hero.color}; transform: scaleX(${ratioProgressBar})`" class="progress-bar"></div>
        </div>
      </div>
      <p v-if="heading" class="main-text">{{ heading }}</p>
      <ChallengeCards
        :color="hero.color"
        :cards="challenges"
        @favorite="handleFavorite"
      />
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import axios from 'axios'
// eslint-disable-next-line import/extensions
import { getTypeName } from '../misc/helpers'
// eslint-disable-next-line import/extensions
import HeroBanner from '@/components/HeroBanner'
// eslint-disable-next-line import/extensions
import ChallengeCards from '@/components/ChallengeCards'

export default {
  components: { HeroBanner, ChallengeCards },
  data() {
    return {
      hero: {
        color:
          this.$route.params.sportName === 'activite-physique'
            ? 'red'
            : 'purple',
        heading: '',
        breadcrumb: []
      },
      heading:
        this.$route.params.typeName === 'defis'
          ? 'Choisis ton défi'
          : 'Que veux-tu pratiquer ?',
      challenges: [],
      challengesDone: 0,
      ratioProgressBar: 0
    }
  },
  computed: {
    typeName() {
      return getTypeName(this.$route.params.typeName)
    },
    ...mapState('app', ['appTitle', 'apiUrl', 'bearer']),
    ...mapState('authentication', ['user'])
  },
  async mounted() {
    let childrenFilter = ''
    if (this.$route.params.categoryName !== 'general') {
      childrenFilter = `children(slug: "${this.$route.params.categoryName}") {
          title
        }`
    }

    const { sportName, typeName } = this.$route.params
    let { categoryName } = this.$route.params
    if (categoryName === 'general') {
      categoryName = sportName
    }

    const { query } = this.$route
    if (query.redirectChallenge) {
      this.$router.push(
        `/sport/${sportName}/${categoryName}/${this.$route.params.categoryName}/${query.redirectChallenge}`
      )
    }

    const sectionSlug =
      sportName === 'activite-physique' ? 'physicalActivity' : 'sports'
    const graphQlSection =
      sportName === 'activite-physique'
        ? 'physicalActivity_activitePhysique_Entry'
        : 'sports_sports_Entry'

    const query2 = `query MyQuery {
        category(group: "sports", slug: "${sportName}") {
          title
          ${childrenFilter}
        }
        entries(section: "${sectionSlug}", relatedToCategories: [{group: "sports", slug: "${categoryName}"}, {group: "challengeTypes", slug: "${typeName}"}]) {
          sectionHandle
          ... on ${graphQlSection} {
            id
            title
            slug
            image {
              url(format: "webp", width: 700, height: 500, mode: "crop", quality: 100)
            }
            challengeLevel
            challengeOrder
          }
        }
      }`

    try {
      const result = await axios({
        method: 'POST',
        url: this.apiUrl,
        data: {
          query: query2
        },
        headers: {
          Authorization: `Bearer ${this.bearer}`
        }
      })

      const { category, entries } = result.data.data

      // Heading
      this.hero.heading = category.title

      // Breadcrumb
      const breadcrumb = []

      // Level 1
      breadcrumb.push({
        label: `${this.hero.heading}`,
        url: `/sport/${sportName}`
      })
      // Level 2
      breadcrumb.push({
        label: `${this.typeName}`,
        url: `/sport/${sportName}/${typeName}`
      })
      // Level 3
      if (category.children) {
        breadcrumb.push({
          label: `${category.children[0].title}`,
          url: `/sport/${sportName}/${typeName}/${categoryName}`
        })
      }

      this.hero.breadcrumb = breadcrumb

      // Content
      if (entries.length) {
        const challenges = entries.map(el => {
          return {
            id: el.id,
            image: el.image.length ? el.image[0].url : null,
            name: el.title,
            url: `/sport/${sportName}/${typeName}/${
              this.$route.params.categoryName === 'general'
                ? 'general'
                : categoryName
            }/${el.slug}`,
            number: el.challengeOrder,
            difficulty: el.challengeLevel,
            sectionHandle: el.sectionHandle,
            done:
              !!this.user &&
              !!this.user.jasInfo &&
              !!this.user.jasInfo.challenges.length &&
              !!this.user.jasInfo.challenges.find(
                challenge => challenge.id === el.id
              ),
            rate: null
          }
        })

        this.challenges = challenges.sort((a, b) => {
          return a.number - b.number
        })
        this.challengesDone = this.getChallengesDone(this.challenges);
        this.ratioProgressBar = this.challengesDone === 0 ? 0 : this.challengesDone / this.challenges.length
      }
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    ...mapActions('authentication', ['updateUser']),
    async handleFavorite(challenge) {
      const userFavorites = this.user.jasInfo.favorites || []
      let favorites = []

      if (userFavorites) {
        favorites = userFavorites
      }

      if (favorites.includes(challenge.id)) {
        favorites = favorites.filter(f => f !== challenge.id)
      } else {
        favorites.push(challenge.id)
      }

      const updatedUser = {
        ...this.user,
        jasInfo: {
          ...this.user.jasInfo,
          favorites
        }
      }

      await this.updateUser(updatedUser)
    },
    getChallengesDone(challenges) {
      console.log(challenges)
      let allChallengesDone = []
      if (challenges) {
        allChallengesDone = challenges.filter(el => el.done)
        if (!allChallengesDone) {
          allChallengesDone = []
        }
      }
      return allChallengesDone.length
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';
.type-page {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  .page-main {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $light;
    padding: 56px 16px 80px;
    
    .progress {
      max-width: 1100px;
      width: 100%;
      margin-bottom: 20px;

      @media (max-width: $tabletBreakpoint) {
        max-width: 700px;
      }

      @media (max-width: $mobileBreakpoint) {
        max-width: 500px;
      }

      p {
        font-size: 24px;
        text-align: center;
        margin: 20px 0 5px;
      }
      &.red {
        p {
          color: #FED6CB;
        }
        .challenges-done {
          color: #F04B54;
        }
        .progress-bar-container {
          background-color: #FED6CB;
        }
      }
      &.purple {
        p {
          color: #B8B0D7;
        }
        .challenges-done {
          color: #702784;
        }
        .progress-bar-container {
          background-color: #B8B0D7;
        }
      }
      .progress-bar-container {
        position: relative;
        width: 100%;
        height: 5px;
        border-radius: 3px;
        overflow: hidden;
        .progress-bar {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 3px;
          transform-origin: left;
        }
      }
    }
    .main-text {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 145%;
      text-align: center;
      color: $dark;
      margin: 0 0 32px;
    }
  }
}
</style>
