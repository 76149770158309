import firebase from 'firebase/app'
import 'firebase/auth'

// The configuration below is not sensitive data. You can serenely add your config here
const config = {
  apiKey: 'AIzaSyBcmBiyBA1xhLx10ZkRdZK41TpT8y62aeE',
  authDomain: 'rseq-7c922.firebaseapp.com',
  projectId: 'rseq-7c922',
  storageBucket: 'rseq-7c922.appspot.com',
  messagingSenderId: '213123402182',
  appId: '1:213123402182:web:c2749a4e416cceca138ac7'
}

firebase.initializeApp(config)
