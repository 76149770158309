<template>
  <section class="card-list">
    <a v-for="card in cards" :key="card.id" class="card-item">
      <div class="image-container">
        <img class="card-image" :src="card.thumbnail[0].url" loading="lazy" />
      </div>
      <div class="card-content">
        <p class="card-text">
          {{ card.title }}
        </p>
        <router-link class="card-link" :to="`/blog/${card.slug}`">
          Lire l'article
          <svg
            class="chevron"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8 12"
          >
            <path
              d="M1 11.295a.996.996 0 0 0 1.41 0L7 6.705a.996.996 0 0 0 0-1.41L2.41.705A.996.996 0 1 0 1 2.115l3.88 3.89L1 9.885c-.39.39-.38 1.03 0 1.41Z"
              fill="#3E92CC"
            />
          </svg>
        </router-link>
      </div>
    </a>
  </section>
</template>

<script>
export default {
  props: {
    cards: Array
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.card-list {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;

  @media (max-width: $tabletBreakpoint) {
    max-width: 700px;
  }

  @media (max-width: $mobileBreakpoint) {
    max-width: 500px;
  }

  .card-item {
    width: 100%;
    max-width: calc((100% - (48px / 3)) / 3);
    margin: 0 24px 24px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $light;
    border: 1px solid $lightGray;
    filter: drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.03));

    &:nth-child(3n + 3) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: $tabletBreakpoint) {
      max-width: calc((100% - 24px) / 2);

      &:nth-child(3n + 3) {
        margin-right: 24px;
      }

      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }

    @media (max-width: $mobileBreakpoint) {
      max-width: 100%;
      margin-right: 0;

      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }

    .image-container {
      width: 100%;
      height: 250px;
      background-color: #c4c4c4;

      .card-image {
        display: block;
        width: 100%;
        height: 100%;
        object-position: center center;
        object-fit: cover;
      }
    }

    .card-content {
      width: 100%;
      padding: 15px 20px 18px 16px;
    }

    .card-text {
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 145%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 0 0 14px;
    }

    .card-link {
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      color: #3e92cc;
      align-self: end;
      width: 100%;

      .chevron {
        width: 7px;
        height: 12px;
        margin-left: 8px;
        margin-top: 4px;
      }
    }
  }
}
</style>
