<template>
  <section class="ranking-page">
    <HeroBanner color="grey" :heading="heading" />
    <section class="ranking-section">
      <section class="ranking-wrapper">
        <section v-if="this.user && this.user.jasInfo && this.user.jasInfo.school" class="ranking-switcher">
          <div class="switcher-background" :class="{'is-right': mode === 'schools'}"></div>
          <div class="switcher-option" :class="{'is-active': mode === 'general'}" @click="mode = 'general'">Tous les élèves</div>
          <div class="switcher-option" :class="{'is-active': mode === 'schools'}" @click="mode = 'schools'">Mon école</div>
        </section>
        <section class="ranking-container">
          <header class="ranking-header">
            <div class="item-rank">Rang</div>
            <div class="item-username">{{ mode === 'general' ? 'Participant' : user.jasInfo.school || '' }}</div>
            <div class="item-points">Points</div>
          </header>
          <section v-if="mode === 'general'" class="standings-item-list">
            <section v-for="(user, index) in rankByUser" :key="index" class="standings-item" :class="{'is-mine': user.isMe}">
              <div class="item-rank">{{ user.rank }}</div>
              <div class="item-username">{{ user.name }}</div>
              <div class="item-points">{{ pointsFormat(user.points) }}</div>
            </section>
          </section>
          <section v-if="mode === 'schools'" class="standings-item-list">
            <section v-for="(user, index) in rankInMySchools" :key="index" class="standings-item" :class="{'is-mine': user.isMe}">
              <div class="item-rank">{{ user.rank }}</div>
              <div class="item-username">{{ user.name }}</div>
              <div class="item-points">{{ pointsFormat(user.points) }}</div>
            </section>
          </section>
        </section>
      </section>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex'
// eslint-disable-next-line import/extensions
import UsersDB from '@/firebase/users-db'
// eslint-disable-next-line import/extensions
import HeroBanner from '@/components/HeroBanner'

export default {
  components: { HeroBanner },
  data() {
    return {
      heading: 'Classement',
      mode: 'general',
      users: []
    }
  },
  computed: {
    rankByUser() {
      const rankedUsers = this.users.map(el => ({
          rank: 0,
          name: `${el.firstname} ${el.lastname}`,
          points: el.points,
          school: el.school,
          isMe: this.user && el.id === this.user.id
        }))
        .sort((a, b) => { return b.points - a.points })

      let lastPoints = null
      let lastRank = 0
      // eslint-disable-next-line for-direction
      for (let i = 0; i < rankedUsers.length; i += 1) {
        const currentPoints = rankedUsers[i].points

        if (currentPoints !== lastPoints) {
          lastRank += 1
          lastPoints = currentPoints
        }

        rankedUsers[i].rank = lastRank
      }

      return rankedUsers
    },
    rankInMySchools() {
      if (!this.user) return []

      const rankedUsers = this.rankByUser.filter(el => el.school === this.user.jasInfo.school)

      let lastPoints = null
      let lastRank = 0
      // eslint-disable-next-line for-direction
      for (let i = 0; i < rankedUsers.length; i += 1) {
        const currentPoints = rankedUsers[i].points

        if (currentPoints !== lastPoints) {
          lastRank += 1
          lastPoints = currentPoints
        }

        rankedUsers[i].rank = lastRank
      }

      return rankedUsers
    },
    rankBySchool() {
      let rankedSchools = []

      this.users.forEach(user => {
        if (user.points > 0) {
          const foundSchool = rankedSchools.find(el => el.name === user.school)

          if (foundSchool) {
            foundSchool.points += 1
          } else {
            rankedSchools.push({
              name: user.school,
              points: user.points,
              isMine: this.user && user.school === this.user.jasInfo.school
            })
          }
        }
      })

      rankedSchools = rankedSchools.sort((a, b) => { return b.points - a.points })

      let lastPoints = null
      let lastRank = 0
      // eslint-disable-next-line for-direction
      for (let i = 0; i < rankedSchools.length; i += 1) {
        const currentPoints = rankedSchools[i].points

        if (currentPoints !== lastPoints) {
          lastRank += 1
          lastPoints = currentPoints
        }

        rankedSchools[i].rank = lastRank
      }

      return rankedSchools
    },
    ...mapState('app', ['appTitle']),
    ...mapState('authentication', ['user'])
  },
  async mounted() {
    const usersDb = new UsersDB()

    const users = await usersDb.readAll(null, 'jasInfo')

    this.users = users.map(el => ({
      id: el.id,
      firstname: el.firstname,
      lastname: el.lastname,
      school: el.jasInfo.school,
      points: el.jasInfo.challenges.length || 0
    }))
  },
  methods: {
    pointsFormat(points) {
      return (points === 1) ? `${points}pt` : `${points}pts`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.ranking-page {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ranking-section {
    flex: 1;
    width: 100%;
    padding: 50px 24px;
    display: flex;
    justify-content: center;
    background-color: $light;

    .ranking-wrapper {
      width: 100%;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .ranking-switcher {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        background: $lightGray;
        border-radius: 50px;
        padding: 6px 8px;
        margin-bottom: 32px;

        .switcher-background {
          position: absolute;
          top: 6px;
          bottom: 6px;
          width: calc((100% - 16px) / 2);
          left: 8px;
          background-color: $gray;
          border-radius: 30px;
          transition: transform ease-out 0.2s;

          &.is-right {
            transform: translateX(100%);
          }
        }

        .switcher-option {
          position: relative;
          width: 50%;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 145%;
          color: $dark;
          padding: 7px;
          text-align: center;
          transition: color ease-out 0.2s;
          cursor: pointer;

          &.is-active {
            color: $light;
          }
        }
      }

      .ranking-container {
        width: 100%;
        display: flex;
        flex-direction: column;

        .ranking-header {
          width: 100%;
          display: flex;
          align-items: center;

          div {
            padding: 0 8px;
            margin-bottom: 10px;
            font-family: futura-pt, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 145%;
            color: #89969F;
          }
        }

        .item-rank {
          width: 75px;
        }

        .item-username {
          flex: 1;
        }

        .item-points {
          width: 100px;
          text-align: center;
        }

        .standings-item-list {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin: 0;
          padding: 0;

          .standings-item {
            width: 100%;
            display: flex;
            align-items: center;
            margin: 0 0 8px;
            padding: 20px 0;
            background: #F1F2F2;
            border: 1px solid #EDEFF7;
            box-sizing: border-box;
            border-radius: 4px;

            &:last-child {
              margin-bottom: 0;
            }

            &.is-mine {
              background: $yellow;
            }

            div {
              padding: 0 8px;
              font-family: futura-pt, sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 145%;
              color: #000000;
            }

            .item-rank {
              font-weight: bold;
              font-size: 14px;
              padding-left: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
