<template>
  <div id="app">
    <Header />
    <div class="main-wrapper">
      <router-view />
    </div>
    <Footer />

<!--    <new-content-available-toastr-->
<!--      v-if="newContentAvailable"-->
<!--      class="new-content-available-toastr"-->
<!--      :refreshing-app="refreshingApp"-->
<!--      @refresh="serviceWorkerSkipWaiting"-->
<!--    ></new-content-available-toastr>-->
    <apple-add-to-home-screen-modal
      v-if="showAddToHomeScreenModalForApple"
      class="apple-add-to-home-screen-modal"
      @close="closeAddToHomeScreenModal"
    >
    </apple-add-to-home-screen-modal>
    <android-add-to-home-screen-modal
      v-if="showAddToHomeScreenModalForAndroid"
      class="apple-add-to-home-screen-modal"
      @close="closeAddToHomeScreenModal"
    >
    </android-add-to-home-screen-modal>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { seo } from '@/seo.json'
// import NewContentAvailableToastr from '@/components/NewContentAvailableToastr'
// eslint-disable-next-line import/extensions
import AppleAddToHomeScreenModal from '@/components/AppleAddToHomeScreenModal'
import AndroidAddToHomeScreenModal from '@/components/AndroidAddToHomeScreenModal.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    // NewContentAvailableToastr,
    AppleAddToHomeScreenModal,
    AndroidAddToHomeScreenModal,
    Header,
    Footer
  },
  metaInfo() {
    return {
      title: this.seoData.title,
      titleTemplate: `%s | JAS`,
      meta: [
        {vmid: 'description', name: 'description', content: this.seoData.description},
        {vmid: 'og:title', property: 'og:title', content: this.seoData.title},
        {vmid: 'og:image', property: 'og:image', content: this.seoData.image}
      ]
    }
  },
  computed: {
    seoData() {
      return seo.find(el => el.slug === this.$route.path)
    },
    ...mapGetters('app', ['newContentAvailable']),
    ...mapState('app', ['showAddToHomeScreenModalForApple', 'refreshingApp', 'showAddToHomeScreenModalForAndroid'])
  },
  methods: mapActions('app', [
    'closeAddToHomeScreenModal',
    'serviceWorkerSkipWaiting'
  ])
}
</script>

<style lang="scss">
@import '@/theme/app.scss';

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: $lightGray;

  * {
    box-sizing: border-box;
  }

  a {
    font-weight: 500;
    text-decoration: none;
  }

  #app {
    font-family: $fontFurura;
    color: $dark;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .main-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .new-content-available-toastr {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }

    .apple-add-to-home-screen-modal {
      position: absolute;
      bottom: 0;
      right: 0;
      top: 0;
      left: 0;
      height: fit-content;
      width: fit-content;
      margin: auto;
      z-index: 1000;
    }
  }
}
</style>
