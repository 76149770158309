<template>
  <section class="footer">
    <section class="top-section">
      <!-- Facebook -->
      <a href="https://www.facebook.com/RSEQ1" target="_blank">
        <img src="/img/logo-facebook.svg" alt="Facebook">
      </a>
      <!-- Instagram -->
      <a href="https://www.instagram.com/rseq1/?hl=fr-ca" target="_blank">
        <img src="/img/logo-instagram.svg" alt="Instagram">
      </a>
      <!-- Twitter -->
      <a href="https://twitter.com/rseq1" target="_blank">
        <img src="/img/logo-twitter.svg" alt="Twitter">
      </a>
      <!-- Youtube -->
      <a href="https://www.youtube.com/channel/UCOgqSfTFawENQXf4sAeY64A" target="_blank">
        <img src="/img/logo-youtube.svg" alt="Youtube">
      </a>
      <!-- RSEQ1 -->
      <img src="/img/logo-rseq1.svg" alt="RSEQ1">
    </section>
    <section class="bottom-section">
      <img src="/img/logo-rseq.svg" alt="RSEQ">
    </section>
  </section>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;

  .top-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #fcf4d5;

    a {
      display: flex;
      margin: 0 6px;
    }

    img {
      height: 24px;
    }
  }

  .bottom-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    padding: 24px 10px;

    @media (max-width: $mobileBreakpoint) {
    padding-bottom: 100px;
    }

    img {
      height: 45px;
      max-width: 100%;
    }
  }
}
</style>
