<template>
  <section class="faq-page">
    <HeroBanner color="gray" :heading="heading" />
    <section class="faq-section">
      <section class="faq-wrapper">
        <section
          v-for="(item, index) in questions"
          :key="index"
          class="faq-item"
          :class="{ 'is-active': item.active }"
          @click="item.active = !item.active"
        >
          <p class="faq-question" v-html="item.question" />
          <div class="faq-answer" v-html="item.answer" />
        </section>
      </section>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
// eslint-disable-next-line import/extensions
import HeroBanner from '@/components/HeroBanner'

export default {
  components: { HeroBanner },
  data() {
    return {
      heading: '',
      questions: []
    }
  },
  computed: mapState('app', ['appTitle', 'apiUrl', 'bearer']),
  async mounted() {
    const query =
      `query MyQuery {
        entry(slug: "faq") {
          ... on faq_faq_Entry {
            faq {
              ... on faq_BlockType {
                answer
                question
              }
            }
          }
          title
        }
      }`

    try {
      const result = await axios({
        method: 'POST',
        url: this.apiUrl,
        data: {
          query
        },
        headers: {
          'Authorization': `Bearer ${this.bearer}`
        }
      })

      const { entry } = result.data.data

      // Heading
      this.heading = entry.title

      // Questions
      const svgCode = '<img src="https://res.cloudinary.com/agence-hoffman/image/upload/v1622495874/RSEQ/info.svg" alt="" />'
      this.questions = entry.faq.map(el => {
        return {
          question: el.question.replace('**i**', svgCode),
          answer: el.answer.replace('**i**', svgCode),
          active: false
        }
      })
    } catch (error) {
      console.error(error)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.faq-page {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .faq-section {
    flex: 1;
    width: 100%;
    padding: 50px 24px;
    display: flex;
    justify-content: center;
    background-color: $light;

    .faq-wrapper {
      width: 100%;
      max-width: 700px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .faq-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        &.is-active {
          .faq-question::after {
            transform: translateY(-50%) rotate(180deg);
          }

          .faq-answer {
            display: flex;
          }
        }

        .faq-question {
          position: relative;
          width: 100%;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 145%;
          color: $dark;
          padding: 18px 56px 18px 16px;
          background: $lightGray;
          border-radius: 4px;
          margin: 0;
          cursor: pointer;

          &:after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
            width: 24px;
            height: 24px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTYuNzA1IDlhLjk5Ni45OTYgMCAwMDAgMS40MWw0LjU5IDQuNTljLjM5LjM5IDEuMDIuMzkgMS40MSAwbDQuNTktNC41OUEuOTk2Ljk5NiAwIDEwMTUuODg1IDlsLTMuODkgMy44OEw4LjExNSA5Yy0uMzktLjM5LTEuMDMtLjM4LTEuNDEgMHoiIGZpbGw9IiMwMDAiLz48L3N2Zz4=");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            transition: transform ease-out 0.4s;
          }

          & > img {
            height: 20px;
          }
        }

        .faq-answer {
          display: none;
          flex-direction: column;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 145%;
          color: #89969F;
          margin: 16px 0 0;
          padding: 0 16px;

          & > p {
            margin: 0;

            & > img {
              height: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
