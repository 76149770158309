<template>
   <section class="popup-container">
      <div v-if="slides.length != 0" class="popup">
         <div @click="buttonClosePopup" class="popup-close"><p>Passer</p></div>
         <flickity
            :options="flickityOptions"
            ref="flickity"
            @dragMove="dragMove"
            @init="api=$event.flickityApi"
         >
         <div v-for="(slide, index) in slides" :key="index" class="carousel-cell">
            <div class="popup-image"><img :src="slide.imageUrl" alt=""></div>
            <h3 class="popup-title">{{ slide.title }}</h3>
            <p class="popup-description">{{ slide.description }}</p>
         </div>
         <div class="carousel-cell carousel-last-cell">
            <div class="popup-image"><img src="@/assets/img/animation_500_knry3ns4.gif" alt=""></div>
            <h3 class="popup-title">c’est parti!</h3>
            <p class="popup-description">Explore les défis et entraînements</p>
            <a @click="buttonStart" class="button">Commencer</a>
         </div>
       </flickity>
      </div>
   </section>
</template>

<script>
   import Flickity from 'vue-flickity';
   import 'vue-flickity-2/dist/vue-flickity-2.css';
   import { mapActions, mapState } from 'vuex'
   import axios from 'axios';

   export default {
      components: {
         Flickity,
      },
      data() {
         return {
            flickityOptions: {
            initialIndex: 0,
            prevNextButtons: false,
            pageDots: true,
            wrapAround: true,
            },
            api: {},
            slides: [],
         };
      },
      computed: {
         ...mapState('authentication', ['user']),
         ...mapState('app', [
            'apiUrl',
            'bearer'
         ])
      },
      methods: {
         ...mapActions('authentication', ['updateUser']),
         dragMove(event, pointer, moveVector) {
            console.log(event, pointer, moveVector);
         },
         next() {
            this.api.next();
         },
         previous() {
            this.api.previous();
         },
         async buttonStart() {
            const updatedUser = {
               ...this.user,
               jasInfo: {
                  ...this.user.jasInfo,
                  jasOnboardingDone: true
               }
            }
            await this.updateUser(updatedUser)
            window.location.href = "/";
         },
         async buttonClosePopup() {
            const updatedUser = {
               ...this.user,
               jasInfo: {
                  ...this.user.jasInfo,
                  jasOnboardingDone: true
               }
            }

            await this.updateUser(updatedUser)
         }
      },
      async mounted() {
         const query = `query MyQuery {
            globalSet(handle: "onboarding") {
               ... on onboarding_GlobalSet {
                  onboarding {
                     ... on onboarding_BlockType {
                        imageSlide {
                           url(format: "webp", mode: "fit", height: 760, width: 660, quality: 100)
                        }
                        descriptionSlide
                        titleSlide
                     }
                  }
               }
            }
         }`
         let result
         try {
            result = await axios({
            method: 'POST',
            url: this.apiUrl,
            data: {
               query
            },
            headers: {
               Authorization: `Bearer ${this.bearer}`
            }
            })
   
            const data = result.data.data.globalSet.onboarding
            this.slides = data.map(el => {
               return {
                  imageUrl: el.imageSlide[0].url,
                  title: el.titleSlide,
                  description: el.descriptionSlide
               }
            })
         } catch (error) {
            console.error(error)
         }
      }
   }
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';
.popup-container {
   position: fixed;
   inset: 0;
   top: 0;
   background-color: rgba(0, 0, 0, 0.6);
   z-index: 55;
   .popup {
      position: absolute;
      max-width: 330px;
      height: 590px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      border-radius: 8px;
      background-color: #FFFFFF;
      overflow: hidden;

      .popup-close {
         display: flex;
         align-items: center;
         justify-content: center;
         position: absolute;
         bottom: 30px;
         left: 50%;
         transform: translateX(-50%);
         z-index: 2;
         cursor: pointer;
         p {
            color: $lightPurple;
            font-family: $fontFurura;
         }
      }

      .flickity-enabled {
         width: 100%;
         height: 500px;
         .carousel-cell {
            width: 100%;
            height: 100%;
            margin-right: 10px;
            &.carousel-last-cell {
               position: relative;
               .popup-image {
                  height: 160px;
                  img {
                     object-fit: contain;
                  }
               }
               .popup-title {
                  font-size: 32px;
                  line-height: 50px;
                  text-transform: uppercase;
                  font-family: $fontBebas;
                  letter-spacing: 8px;
                  margin-top: 5px;
               }
               .popup-description {
                  margin-top: 20px;
                  font-weight: 500;
                  font-family: $fontFurura;
               }
               .button {
                  display: block;
                  margin: 130px 35px 0;
                  cursor: pointer;
               }
            }
            .popup-image {
               height: 290px;
               width: 100%;
               border-radius: 0 0 150px 0;
               overflow: hidden;
               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: top;
               }
            }
            .popup-title {
               font-size: 24px;
               font-weight: 500;
               text-align: center;
               margin: 50px 0 5px;
               color: $purple;
            }
            .popup-description {
               font-size: 16px;
               font-weight: 500;
               color: $purple;
               padding: 0 30px;
               text-align: center;
            }
            
         }
      }
   }
}
</style>
 