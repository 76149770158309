<template>
  <div class="header">
    <router-link to="/" class="main-logo" @click.native="clickOnLink()">
      <svg
        class="logo"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 984 984"
      >
        <path
          d="M492 983.7c271.558 0 491.7-220.142 491.7-491.7S763.558.3 492 .3C220.441.3.3 220.442.3 492S220.44 983.7 492 983.7Z"
          fill="#F1F1F2"
        />
        <path
          d="M583.7 402.9c-.1-3.4-.5-6.9-1-10.4-5.3-38.2-34.201-70.3-72.101-79.8l-.399-.1c-1.1-.3-2.3-.5-3.5-.8l-1.2-.3c-.8-.2-1.6-.3-2.4-.4l-2.301-.4c-.6-.1-1.199-.2-1.799-.2l-1.3-.2c-.5-.1-1.1-.1-1.6-.2-.6-.1-1.201-.1-1.801-.1H493c-.5 0-1.1-.1-1.6-.1-1.4-.1-2.7-.1-4.3-.1h-1.2c-1.4 0-2.701 0-4.101.1-.5 0-1 .1-1.5.1l-1.5.1c-.6 0-1.199.1-1.699.1s-1 .1-1.5.2l-1.5.2c-.6.1-1.1.1-1.7.2l-2.3.4c-.8.1-1.601.3-2.301.4l-1.299.3c-1.1.2-2.2.5-3.4.8l-.4.1c-37.9 9.6-66.9 41.6-72.1 79.7-.5 3.4-.9 6.8-1 10.3v.1c0 .2-.1.6-.1.8l.6 182.1v2.7c0 12.1 9.4 21.2 21.8 21.2 5.8 0 11.299-2.3 15.399-6.5 4.1-4.1 6.301-9.7 6.301-15.6l-.301-183.1c.2-3.5.7-6.9 1.5-10.3 5.7-23.2 26.501-39.9 50.401-40.6h3c24.5.7 45.299 17.9 50.599 41.8.7 3.2 1.101 6.2 1.201 8.8l-.3 183.3c0 5.9 2.2 11.4 6.3 15.6 4.1 4.2 9.6 6.5 15.4 6.5 12.4 0 21.8-9.1 21.8-21.2v-2.9l.599-182s.001-.4-.099-.6Z"
          fill="#72217B"
        />
        <path
          d="M486.599 504.6c12.261 0 22.2-9.939 22.2-22.2s-9.939-22.2-22.2-22.2c-12.26 0-22.2 9.939-22.2 22.2s9.94 22.2 22.2 22.2Z"
          fill="#72217B"
        />
        <path
          d="M761.6 410.3c-4.3-4-9.1-7-14.9-7.2-5.6-.2-11.1 1.9-15.5 5.8s-6.9 9.2-7.2 15.1c-.3 5.8 1.7 11.4 5.7 15.9L764 478c18 22.2 15.4 54.5-5.9 73.5s-53.7 17.8-73.8-2.6c-8.1-8.7-21.5-9.2-30.5-1.1-7.8 6.9-9.7 19.7-4.1 27.9 1.2 1.7 1.6 2.2 3 3.6 17.7 18.2 40.7 28.1 64.2 29.5 24.9 1.5 50.3-6.6 70.4-24.5 38.6-34.4 43.9-92.4 12.1-132.2-.6-1-35.1-39.3-37.8-41.8Z"
          fill="#FFD600"
        />
        <path
          d="M677 505.3c.7.8 1.5 1.7 1.9 2.2 4.2 5 10 7.6 15.9 7.6 5 0 10.1-1.8 14.4-5.5 4.5-3.8 7.1-9.1 7.6-14.9.4-5.8-1.4-11.5-5.3-16l-33.4-38.9c-17.4-22.7-14.1-54.9 7.7-73.4 21.8-18.5 54.2-16.5 73.7 4.3 7.9 8.9 21.3 9.7 30.5 1.9 7.9-6.7 10.1-19.4 4.7-27.8-1.2-1.8-1.6-2.3-2.9-3.6-35.5-38.4-94.5-42-134.4-8.2-39.4 33.5-46.1 91.3-15.2 131.9.5.9 1.2 1.8 1.9 2.7l32.9 37.7ZM816.2 380c-12.2 0-22.2 9.9-22.2 22.2 0 12.2 9.9 22.2 22.2 22.2 12.3 0 22.2-9.9 22.2-22.2 0-12.3-10-22.2-22.2-22.2Z"
          fill="#FFD600"
        />
        <path
          d="M349.9 333.8V330.9c0-12.1-9.4-21.2-21.8-21.2-5.8 0-11.3 2.3-15.4 6.5-4.1 4.1-6.3 9.7-6.3 15.6l.3 183.1c-1.4 28.6-24.8 51-53.4 51-28.6 0-52-22.4-53.4-51-.3-11.9-10-21.2-22-21.2-10.4 0-20.3 8.3-21.6 18.1-.2 2.1-.2 2.8-.2 4.6 2.2 52.3 44.9 93.2 97.2 93.2 51.7 0 94.2-39.8 96.9-90.7.2-1 .3-2.1.3-3.2l-.6-181.9Z"
          fill="#E83F4B"
        />
        <path
          d="M177.3 433c-12.2 0-22.2 9.9-22.2 22.2 0 12.3 9.9 22.2 22.2 22.2 12.2 0 22.2-9.9 22.2-22.2 0-12.3-10-22.2-22.2-22.2Z"
          fill="#E83F4B"
        />
        <path
          d="M467.099 737.7c11.433 0 20.7-9.268 20.7-20.7 0-11.432-9.267-20.7-20.7-20.7-11.432 0-20.7 9.268-20.7 20.7 0 11.432 9.268 20.7 20.7 20.7Z"
          fill="#FFD600"
        />
        <path
          d="M484.399 677.4c11.432 0 20.7-9.268 20.7-20.7 0-11.432-9.268-20.7-20.7-20.7-11.432 0-20.7 9.268-20.7 20.7 0 11.432 9.268 20.7 20.7 20.7Z"
          fill="#E83F4B"
        />
        <path
          d="M527.899 719.1c11.433 0 20.7-9.268 20.7-20.7 0-11.432-9.267-20.7-20.7-20.7-11.432 0-20.7 9.268-20.7 20.7 0 11.432 9.268 20.7 20.7 20.7Z"
          fill="#72217B"
        />
      </svg>
    </router-link>
    <ul class="header-nav" :class="{ 'is-active': menuOpened }">
      <li
        v-for="(navlink, index) in navigation"
        :key="index"
        class="nav-item"
        :class="{
          'has-children': navlink.sublinks,
          'is-active': navlink.active
        }"
      >
        <router-link
          :to="navlink.url || '#'"
          :class="`nav-link ${navlink.color}`"
          @click.native="clickOnLink(navlink)"
        >
          {{ navlink.label }}
          <svg
            v-if="navlink.sublinks"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 10 7"
          >
            <path
              d="M.608 2.645l3.454 3.777c.52.569 1.36.569 1.88 0l3.453-3.777c.84-.919.24-2.494-.947-2.494H1.542C.355.151-.232 1.726.608 2.645z"
              fill="#F04B54"
            />
          </svg>
        </router-link>

        <section v-if="navlink.sublinks" class="sub-nav">
          <router-link
            v-for="(sublink, index) in navlink.sublinks"
            :key="index"
            :to="sublink.url"
            class="sub-link"
            @click.native="clickOnLink()"
            >{{ sublink.label }}</router-link
          >
        </section>
      </li>
      <li v-if="user" class="nav-item">
        <router-link
          to="/mon-profil"
          class="nav-link"
          @click.native="clickOnLink()"
        >
          Mon profil
        </router-link>
      </li>
      <li v-else class="nav-buttons">
        <router-link
          to="/connexion"
          class="nav-link"
          @click.native="clickOnLink()"
        >
          Se connecter
        </router-link>
        <router-link
          to="/inscription"
          class="nav-button"
          @click.native="clickOnLink()"
        >
          S'inscrire
        </router-link>
      </li>
      <li v-if="user" class="nav-buttons">
        <router-link to="/favoris" class="nav-favorites" @click.native="clickOnLink()">
          <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15">
            <path d="M4.342.934A4.07 4.07 0 0 0 1.41 2.19c-1.614 1.67-1.612 4.368 0 6.039l6.17 6.392a.581.581 0 0 0 .834 0l6.176-6.386c1.614-1.67 1.614-4.37 0-6.04a4.066 4.066 0 0 0-5.87 0L8 2.94l-.726-.75A4.068 4.068 0 0 0 4.342.935Z" fill="#702784"/>
          </svg>
        </router-link>
      </li>
    </ul>
    <div class="burger-menu" @click.prevent="menuOpened = !menuOpened">
      <svg
        v-if="!menuOpened"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 40 40"
      >
        <path
          d="M4.4 8.001v4h31.2v-4H4.4zm0 10v4h31.2v-4H4.4zm0 10v4h31.2v-4H4.4z"
          fill="#000"
        />
      </svg>
      <svg
        v-else
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 40 40"
      >
        <path
          d="M30.5 9.516a1.66 1.66 0 00-2.35 0L20 17.649 11.85 9.5a1.66 1.66 0 00-2.35 0c-.65.65-.65 1.7 0 2.35L17.65 20 9.5 28.15c-.65.65-.65 1.7 0 2.35.65.65 1.7.65 2.35 0L20 22.35l8.15 8.15c.65.65 1.7.65 2.35 0 .65-.65.65-1.7 0-2.35L22.35 20l8.15-8.15a1.67 1.67 0 000-2.333z"
          fill="#000"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      navigation: [
        {
          label: 'Activité physique',
          color: 'dark',
          active: false,
          sublinks: [
            {
              label: 'Défis',
              url: '/sport/activite-physique/defis'
            },
            {
              label: 'Entraînements',
              url: '/sport/activite-physique/entrainements'
            }
          ]
        },
        {
          label: 'Sport',
          color: 'dark',
          active: false,
          sublinks: [
            {
              label: 'Basketball',
              url: '/sport/basketball'
            },
            {
              label: 'Soccer',
              url: '/sport/soccer'
            },
            {
              label: 'Hockey',
              url: '/sport/hockey'
            },
            {
              label: 'Football et flag-football',
              url: '/sport/football'
            }
          ]
        },
        {
          label: 'S\'informer',
          color: 'dark',
          active: false,
          url: '/lexique'
        },
        {
          label: 'Classement',
          color: 'dark',
          active: false,
          url: '/classement'
        },
        {
          label: 'Actualités',
          color: 'dark',
          active: false,
          url: '/blog'
        },
        {
          label: 'FAQ',
          color: 'dark',
          active: false,
          url: '/faq'
        },
        {
          label: 'Nous joindre',
          color: 'dark',
          active: false,
          url: '/contact'
        }
      ],
      menuOpened: false
    }
  },
  computed: {
    ...mapState('app', ['appTitle']),
    ...mapState('authentication', ['user'])
  },
  methods: {
    clickOnLink(navlink = false) {
      if (navlink && navlink.sublinks) {
        navlink.active = !navlink.active
      } else {
        this.menuOpened = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/app.scss';

.header {
  position: sticky;
  top: 0;
  width: 100%;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $light;
  z-index: 50;

  @media (max-width: $tabletBreakpoint) {
    padding: 7px 16px;
  }

  .main-logo {
    display: flex;

    .logo {
      height: 55px;
    }
  }

  .header-nav {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;

    @media (max-width: $tabletBreakpoint) {
      position: fixed;
      z-index: 40;
      top: 69px;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $light;
      display: none;
      flex-direction: column;
      align-items: flex-start;
      padding: 45px 16px 16px;
      overflow-y: scroll;
      //opacity: 0;
      //pointer-events: none;
      //transform: translateY(20px);

      &.is-active {
        display: flex;
        //transform: translateY(0);
        //opacity: 1;
        //pointer-events: auto;
        //transition: opacity ease-out 0.2s, transform ease-out 0.2s;
      }
    }

    .nav-item {
      margin-right: 40px;

      &.has-children {
        position: relative;

        .nav-link svg {
          display: block;
        }

        @media (min-width: $tabletBreakpoint + 1) {
          &:hover {
            .sub-nav {
              opacity: 1;
              transform: translateY(0);
              pointer-events: auto;
            }
          }
        }

        .sub-nav {
          position: absolute;
          top: 50px;
          right: -20px;
          width: 200px;
          background-color: $light;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 15px 0;
          border-radius: 5px;
          box-shadow: 0 0 50px 0 rgba(#202020, 0.15);
          opacity: 0;
          transform: translateY(10px);
          transition: opacity ease-out 0.2s, transform ease-out 0.2s;
          pointer-events: none;

          @media (max-width: $tabletBreakpoint) {
            position: relative;
            display: none;
            opacity: 1;
            pointer-events: auto;
            top: initial;
            right: initial;
            width: 100%;
            padding: 0;
            border-radius: 0;
            box-shadow: none;
            transform: none;
          }

          @media (min-width: $tabletBreakpoint + 1) {
            &:before {
              content: '';
              position: absolute;
              width: 20px;
              height: 20px;
              background-color: $light;
              transform: rotate(45deg);
              top: -7px;
              right: 20px;
            }
          }

          .sub-link {
            display: block;
            width: 100%;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 145%;
            color: #000000;
            padding: 8px 15px;
            transition: background-color ease-out 0.2s;

            @media (max-width: $tabletBreakpoint) {
              padding: 0 0 0 32px;
              margin-top: 16px;
            }

            @media (min-width: $tabletBreakpoint + 1) {
              &:hover {
                background-color: rgba($lightGray, 0.5);
              }
            }
          }
        }
      }

      @media (max-width: $tabletBreakpoint) {
        margin-right: 0;
        margin-bottom: 32px;

        &.is-active {
          .sub-nav {
            display: flex;
          }
        }
      }

      .nav-link {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 145%;
        padding: 15px 0;
        color: $dark;

        @media (max-width: $tabletBreakpoint) {
          font-size: 20px;
          padding: 0;
        }

        &.red {
          color: $red;

          svg path {
            fill: $red;
          }

          svg polygon {
            stroke: $red;
          }
        }

        &.purple {
          color: $purple;

          svg path {
            fill: $purple;
          }

          svg polygon {
            stroke: $red;
          }
        }

        &.yellow {
          color: $yellow;
        }

        &.gray {
          color: $gray;
        }

        &.blue {
          color: $blue;
        }

        &.dark {
          color: $dark;
        }

        svg {
          display: none;
          height: 7px;
          margin-left: 12px;
        }
      }
    }

    .nav-buttons {
      display: flex;
      align-items: center;

      @media (max-width: $tabletBreakpoint) {
        width: 100%;
        flex: 1;
        flex-direction: column-reverse;
        justify-content: center;
      }

      .nav-link {
        font-weight: bold;
        font-size: 16px;
        line-height: 145%;
        padding: 15px 0;
        color: $dark;
        margin-right: 40px;

        @media (max-width: $tabletBreakpoint) {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 145%;
          text-align: center;
          text-decoration-line: underline;
          margin-right: 0;
          margin-top: 16px;
          padding: 0;
        }
      }

      .nav-button {
        @extend .button;

        @media (min-width: $tabletBreakpoint + 1) {
          min-width: auto;
          padding: 12px 17px;
        }
      }
    }

    .nav-favorites {
      position: relative;
      display: inline-block;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      box-shadow: 0 0 25px 0 rgba(#202020, 0.1);

      @media (max-width: $tabletBreakpoint) {
        display: none;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 26px;
      }
    }
  }

  .burger-menu {
    display: none;
    cursor: pointer;

    @media (max-width: $tabletBreakpoint) {
      display: flex;
    }

    svg {
      height: 40px;
    }
  }
}
</style>
