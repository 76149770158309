<template>
  <h3 class="loading-title">
    Chargement...
  </h3>
</template>

<script>
import { mapState } from 'vuex'
import { isNil } from 'lodash'
// eslint-disable-next-line import/extensions
import { isProfileComplete } from '@/misc/helpers'

export default {
  computed: mapState('authentication', ['user']),
  watch: {
    user: {
      handler(user) {
        if (user === undefined) return

        if (this.$route.query.redirectUrl === this.$route.path) {
          this.$router.push('/')
        }

        let { redirectUrl } = this.$route.query

        if (isNil(user)) {
          redirectUrl = `/connexion?redirectUrl=${this.$route.query.redirectUrl}`
        } else if (!isProfileComplete(user)) {
          redirectUrl = '/mon-profil'
        } else {
          redirectUrl = this.$route.query.redirectUrl
        }

        this.$router.push(redirectUrl)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-title {
  text-align: center;
}
</style>
